import React, { useCallback, useEffect, useState } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { TitlePage } from '@src/@styles/styles.global';
import { useParams } from 'react-router';

import Button from '@components/Shared/Buttons/Button';
import UFs from '@components/Shared/BuscaEstados/UF2.json';

import Services from './services';
import { useSnackbar } from 'notistack';
import TableActions from '@src/components/Shared/Tables/TableActions';
import Modal from '@components/Shared/Modal/ModalPure';
import DatePicker from '@components/Shared/DatePicker';
import InputEstadoCidade from './components/inputEstadoCidade/InputEstadoCidade';
import InputFitros from './components/tags/inputTag';

import {
  Add,
  AddOutlined,
  ChevronLeft,
  ChevronRight,
  Clear,
  SaveOutlined,
  Visibility,
} from '@material-ui/icons';

import { Divider, Form, Input, Select, Switch } from 'antd';
import ButtonAnt from 'antd/lib/button';
import { PlusOutlined } from '@ant-design/icons';

import TextArea from 'antd/lib/input/TextArea';
import { CidadesIn, ObjetoFiltrosVinculo } from './utils';
// import { Container } from './styles';

import { useAuth } from '@hooks/AuthenticateContext';

interface IEstrategias {
  id: number;
  descricao: string;
  estrategias: number;
  data_fim_campanha: string;
}

export interface IFiltro {
  filtro_id: number;
  filtro_descricao: string;
}
export interface ITipoFiltro {
  tipo_filtro_id: number;
  tipo_filtro_descricao: string;
  filtros: IFiltro[];
}

const columnsLabel = {
  id: 'ID',
  nome: 'Estratégias',
  quantidade_perfis: 'Cadastros',
  data_fim_estrategia: 'Encerra em:',
};

const DetalhesCampanha: React.FC = () => {
  const useRef = React.useRef<any>(null);
  const { Option } = Select;
  const { idCampanha }: { idCampanha: string } = useParams();
  const [campanhaNome, setCampanhaNome] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const [loadingButton, setLoadingButton] = useState(false);

  const [date, setDate] = useState([new Date()]);
  const [dateCadastro, setDateCadastro] = useState([]);
  const [dateDoacao, setDateDoacao] = useState([]);
  const [status_ativo, setStatus_ativo] = useState(1);
  const [data, setData] = useState<IEstrategias[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [, setTotalItems] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [showcreateEstrategia, setShowCreateEstrategia] = useState(false);
  const [etapa, setEtapa] = useState(0);
  const [formRef] = Form.useForm();
  const [statusFiltro, setStatusFiltro] = useState<IFiltro[]>([]);
  const [cadastroFiltro, setCadastroFiltro] = useState<IFiltro[]>([]);
  const [formaContribuicaoFiltro, setFormaContribuicaoFiltro] = useState<
    IFiltro[]
  >([]);
  const [tipoCorrespondenciaFiltro, setTipoCorrespondenciaFiltro] = useState<
    IFiltro[]
  >([]);
  const [tipoPerfilFiltro, setTipoPerfilFiltro] = useState<IFiltro[]>([]);
  const [estrategiaManual, setEstrategiaManual] = useState(true);

  const [origemDoacao, setOrigemDoacao] = useState<any[]>([]);
  const [motivo, setMotivo] = useState<any[]>([]);
  const [origemEspecifica, setOrigemEspecifica] = useState<any[]>([]);

  const handleEstrategiaManual = (values: any) => {
    if (values === '0') setEstrategiaManual(false);
    else setEstrategiaManual(true);
  };

  async function createEstrategia(values: any) {
    const filtros = useRef?.current?.getFiltros();
    setLoadingButton(true);
    const { inCampanhas, notInCampanhas, inEstrategias, notInEstrategias } =
      ObjetoFiltrosVinculo(filtros);

    // values.cidades.map((item: any) => {
    //   cidades.push(JSON.stringify(item.cidades));
    // });

    const cidadesIn = CidadesIn(values.cidades);

    const objetoRequisicao = {
      //RAIZ
      nome: values.EstrategiaNome, //nome
      campanha_id: idCampanha, //campanha_id
      descricao: values.EstrategiaDescricao, //descricao
      status_ativo: 1, // status_ativo

      data_inicio_estrategia: date[0], //data_inicio_estrategia
      data_fim_estrategia: date[1], //data_fim_estrategia
      //RAIZ

      //filtros_padrao_cadastro OK
      filtros_padrao_cadastro: {
        filtros_cadastro_ativo_documento_valido: values.selectCadastro, //filtros_cadastro_ativo_documento_valido
        filtros_forma_contribuicao: values.selectFormaContribuicao, //filtros_forma_contribuicao
        filtros_tipo_correspondencia: values.selectTipoCorrespondencia, //filtros_tipo_correspondencia
        filtros_tipo_perfil: values.selectTipoPerfil, //filtros_tipo_perfil
        filtros_origem_especifica: values.selectOrigemEspecifica, //filtros_origem_especifica
        filtros_motivo_cadastro: values.selectMotivo, //filtros_motivo_cadastro
      },
      //filtros_padrao_cadastro OK

      //filtros_endereco OK
      filtros_endereco: {
        estados_in_sigla: values.selectEstadoIn, // estados_in_sigla
        cidades_in_id: cidadesIn,
      },
      //filtros_endereco OK
      gerar_boleto: values.gerar_boleto, //gerar_boleto
      usa_status: values.selectEstrategiaManual === '0' ? true : false, ////usa_status
      filtro_status_id: estrategiaManual ? 0 : values.selectStatus, //filtro_status_id

      //filtros_personalizados
      filtros_personalizados: {
        data_inicio_cadastro: dateCadastro[0],
        data_fim_cadastro: dateCadastro[1],

        data_inicio_doacao: dateDoacao[0],
        data_fim_doacao: dateDoacao[1],

        com_doacao: values.QuantidadeDoacao > 0 ? true : false,
        soma_doacao: values.SomaDoacao,

        quantidade_doacao: values.QuantidadeDoacao, // quantidade_doacao
        filtros_origem_doacao: values.selectOrigemDoacao, //filtros_origem_doacao
        filtros_vinculo: {
          estrategias_feitas_in: inEstrategias,
          estrategias_feitas_not_in: notInEstrategias,
        },
      },
      //filtros_personalizados
    };

    const services = new Services();

    const { error } = await services.createEstrategias(objetoRequisicao);

    if (!error) {
      enqueueSnackbar('Estratégia criada com sucesso!', {
        title: 'Estratégia criada com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      firstLoadEstrategia();
      setShowCreateEstrategia(false);
      setLoadingButton(false);
      return true;
    } else {
      enqueueSnackbar('Erro ao criar estratégia!', {
        title: 'Erro ao criar estratégia!',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingButton(false);
      return false;
    }
  }

  const HandleStatusAtivo = async (value: number) => {
    await setStatus_ativo(value);

    LoadEstrategia(value);
  };

  const HandleChangeEtapa = (value: number) => {
    setEtapa(value);
  };

  const HandleCreateEstrategia = () => {
    setShowCreateEstrategia(!showcreateEstrategia);
    HandleChangeEtapa(0);
  };

  const setValuesOrError = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setData(response.modelo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  function setPagination(page: number, itemsPage: number) {
    if (itemsPerPage !== itemsPage) {
      page = 1;
    }

    setItemsPerPage(itemsPage);
    onChangeSubmit(page, itemsPage);
  }

  async function onChangeSubmit(page: number, itemsPerPage: number) {
    setLoading(true);
    setPage(page);
    const services = new Services();

    const { error, response } = await services.getEstrategias({
      pageNumber: page,
      pageSize: itemsPerPage,
      id: idCampanha,
      status_ativo: status_ativo,
    });

    setValuesOrError(error, response);
  }

  const firstLoad = useCallback(async () => {
    const services = new Services();

    const { error, response } = await services.getCampanhas({
      id: Number(idCampanha),
    });
    if (!error) {
      if (response) setCampanhaNome(response.descricao);
    }
  }, []);

  const firstLoadEstrategia = useCallback(async () => {
    const services = new Services();

    const { error, response } = await services.getEstrategias({
      id: Number(idCampanha),
      pageNumber: page,
      pageSize: itemsPerPage,
      status_ativo: status_ativo,
    });

    setValuesOrError(error, response);
  }, [status_ativo]);

  const LoadEstrategia = async (value: number) => {
    const services = new Services();
    setLoading(true);

    const { error, response } = await services.getEstrategias({
      id: Number(idCampanha),
      pageNumber: page,
      pageSize: itemsPerPage,
      status_ativo: value,
    });

    setValuesOrError(error, response);
  };

  const LoadTiposFiltros = useCallback(async () => {
    const services = new Services();

    const { error, response } = await services.getFiltros();

    setStatusFiltro(response.statusFiltro);
    setCadastroFiltro(response.cadastroFiltro);
    setFormaContribuicaoFiltro(response.formaContribuicaoFiltro);
    setTipoCorrespondenciaFiltro(response.tipoCorrespondenciaFiltro);
    setTipoPerfilFiltro(response.tipoPerfilFiltro);
    setMotivo(response.tipoMotivo);
    setOrigemEspecifica(response.tipoOrigemEspecifica);
    setOrigemDoacao(response.tipoOrigemDocao);
  }, []);

  const LoadOrigemDoacao = useCallback(async () => {
    const services = new Services();
    const { error, response } = await services.getOrigensDoacao();

    if (!error && response) {
      setOrigemDoacao(response);
    } else {
      console.log('Erro ao carregar origens');
    }
  }, []);

  const LoadMotivos = useCallback(async () => {
    const services = new Services();
    const { error, response } = await services.getMotivos();

    if (!error && response) {
      setMotivo(response);
    } else {
      console.log('Erro ao carregar motivos');
    }
  }, []);

  const LoadOrigemEspecifica = useCallback(async () => {
    const services = new Services();
    const { error, response } = await services.getOrigemEspecifica();

    if (!error && response) {
      setOrigemEspecifica(response);
    } else {
      console.log('Erro ao carregar Origens Especifica');
    }
  }, []);

  useEffect(() => {
    window.document.title = `CRM - Campanha`;
    firstLoad();
    firstLoadEstrategia();
    LoadTiposFiltros();
    // LoadOrigemDoacao();
    // LoadMotivos();
    // LoadOrigemEspecifica();
  }, []);

  const getAllCadastro = () => {
    const ids: any = [];

    cadastroFiltro.map(item => ids.push(item.filtro_id));

    formRef.setFieldsValue({
      selectCadastro: ids,
    });
  };

  const clearCadastro = () => {
    formRef.setFieldsValue({
      selectCadastro: [],
    });
  };

  const getAllFormaContribuicao = () => {
    const ids: any = [];

    formaContribuicaoFiltro.map(item => ids.push(item.filtro_id));

    formRef.setFieldsValue({
      selectFormaContribuicao: ids,
    });
  };

  const clearFormaContribuicao = () => {
    formRef.setFieldsValue({
      selectFormaContribuicao: [],
    });
  };

  const getAllTipoCorrespondecia = () => {
    const ids: any = [];

    tipoCorrespondenciaFiltro.map(item => ids.push(item.filtro_id));

    formRef.setFieldsValue({
      selectTipoCorrespondencia: ids,
    });
  };

  const clearTipoCorrespondencia = () => {
    formRef.setFieldsValue({
      selectTipoCorrespondencia: [],
    });
  };

  const getAllTipoPerfil = () => {
    const ids: any = [];

    tipoPerfilFiltro.map(item => ids.push(item.filtro_id));

    formRef.setFieldsValue({
      selectTipoPerfil: ids,
    });
  };

  const clearTipoPerfil = () => {
    formRef.setFieldsValue({
      selectTipoPerfil: [],
    });
  };

  const getAllEstadosIn = () => {
    const ids: any = [];

    UFs.UF.map((estado: any) => ids.push(estado.label));

    formRef.setFieldsValue({
      selectEstadoIn: ids,
    });
  };

  const clearEstadosIn = () => {
    formRef.setFieldsValue({
      selectEstadoIn: [],
    });
  };

  const getAllOrigemEspecifica = () => {
    const ids: any = [];

    origemEspecifica.map(item => ids.push(item.filtro_id));

    formRef.setFieldsValue({
      selectOrigemEspecifica: ids,
    });
  };

  const clearOrigemEspecifica = () => {
    formRef.setFieldsValue({
      selectOrigemEspecifica: [],
    });
  };

  const getAllOrigemDoacao = () => {
    const ids: any = [];

    origemDoacao.map(item => ids.push(item.filtro_id));

    formRef.setFieldsValue({
      selectOrigemDoacao: ids,
    });
  };

  const clearOrigemDoacao = () => {
    formRef.setFieldsValue({
      selectOrigemDoacao: [],
    });
  };

  const getAllMotivos = () => {
    const ids: any = [];

    motivo.map(item => ids.push(item.filtro_id));

    formRef.setFieldsValue({
      selectMotivo: ids,
    });
  };

  const clearMotivos = () => {
    formRef.setFieldsValue({
      selectMotivo: [],
    });
  };

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM SOCIALLIS') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <>
      <Grid container>
        <Grid item sm={7} xs={12}>
          <Grid
            item
            sm={12}
            xs={12}
            style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
          >
            <TitlePage style={{ margin: 0 }}>{campanhaNome}</TitlePage>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            justify="center"
            style={{
              alignSelf: 'center',
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 10,
            }}
          >
            <span style={{ fontSize: 16 }}>ID: {idCampanha}</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          sm={2}
          xs={6}
          style={{
            alignSelf: 'center',
            padding: 5,
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <Button
            color={status_ativo === 1 ? 'secondary' : 'default'}
            style={{ width: '100%' }}
            onClick={() => HandleStatusAtivo(1)}
          >
            Em andamento
          </Button>
        </Grid>
        <Grid
          item
          sm={2}
          xs={6}
          style={{
            alignSelf: 'center',
            padding: 5,
          }}
        >
          <Button
            color={status_ativo === 0 ? 'secondary' : 'default'}
            style={{ width: '100%' }}
            onClick={() => HandleStatusAtivo(0)}
          >
            Finalizadas
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} lg={9} style={{ padding: 5 }}>
          <TableActions
            loading={loading}
            columns={[columnsLabel]}
            data={data}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            pageCurrent={page}
            fetchData={setPagination}
            Actions={[
              {
                Button: (row: IEstrategias) => {
                  return (
                    <IconButton
                      onClick={() => window.open(`/estrategia/${row.id}/`)}
                    >
                      <Visibility />
                    </IconButton>
                  );
                },
              },
            ]}
          />
        </Grid>
      </Grid>

      {/* Modal */}

    </>
  );
};

export default DetalhesCampanha;
