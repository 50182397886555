import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { SearchOutlined, TuneOutlined, Visibility } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';

import { TitlePage } from '@styles/styles.global';
import Tag from '@components/Shared/Tag';
import TableActions from '@components/Shared/Tables/TableCustomKeys';
import { Input, Select as SelectAntd, Form } from 'antd';
import InvisibleContent from '@components/Shared/InvisibleContent';

import useDebounce from '@hooks/useDebounce';
import { MaskCPFeCNPJ } from '@utils/Masks';
import DatePicker from '@src/components/Shared/DatePicker';
import Button from '@components/Shared/Buttons/Button';

import PageListPessoasServices, {
  IPessoa,
  IFormaContribuicao,
  IPerfil,
  IOrigemEspecifica,
  IMotivo,
  ICidade,
} from './services';
import { subDays } from 'date-fns';

import InputCidades from '@components/Shared/EstadoCidadeInputs/cidades';

import UFs from '@components/Shared/BuscaEstados/UF2.json';

import { useLoading } from '@hooks/LoadingContext';
import Filtros from '@src/components/Shared/Filtros';

import { useAuth } from '@hooks/AuthenticateContext';

const columnsLabel = {
  id: 'ID',
  nome: 'Nome',
  data_cadastro: 'Cadastrado em:',
  documento: 'CPF/CNPJ',
};

const tiposFiltros = ['Simples', 'Detalhada'];
const optionsFilter = ['ID', 'ID AFIPE', 'ID Perfil', 'CPF/CNPJ', 'Nome', 'E-mail', 'Telefone', 'Sem filtro'];

const ListPessoas: React.FC = () => {
  const { setValues, removeLoading } = useLoading();
  const [formRef] = Form.useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPessoa[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItens, setTotalItems] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(
    optionsFilter[optionsFilter.length - 1],
  );
  const [valueInput, setValueInput] = useState('');
  const [tipoFiltroSelected, setTipoFiltroSelected] = useState<
    'Simples' | 'Detalhada'
  >();

  const [cidades, setCidades] = useState<ICidade[]>([]);
  const [formasContribuicao, setFormasContribuicao] = useState<
    IFormaContribuicao[]
  >([]);
  const [tiposPerfil, setTiposPerfil] = useState<IPerfil[]>([]);
  const [origensEspecificas, setOrigensEspecificas] = useState<
    IOrigemEspecifica[]
  >([]);
  const [motivos, setMotivos] = useState<IMotivo[]>([]);

  const [periodo, SetPerido] = useState<any>([
    subDays(new Date(), 14),
    new Date(),
  ]);
  const [idFormaContribuicao, setIdFormaContribuicao] = useState<number>(0);
  const [idTipoPerfil, setIdTipoPerfil] = useState<number>(0);
  const [idOrigemEspecifica, setIdOrigemEspecifica] = useState<number>(0);
  const [idMotivo, setIdMotivo] = useState<number>(0);
  const [estado, setEstado] = useState<string>('');
  const [idCidade, setIdCidade] = useState<any>();
  const [filtros, setFiltros] = useState(false);

  const setValuesOrError = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setData(response.pessoas);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  async function loadFormaContribuicao() {
    setValues(true);
    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } =
      await pageListPessoasServices.loadFormaContribuicao();

    if (!error && response) {
      setFormasContribuicao(response);
    }
  }

  async function loadTiposPerfil() {
    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.GetTiposPerfil();

    if (!error && response) {
      setTiposPerfil(response);
    }
  }

  async function loadOrigensEspecificas() {
    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } =
      await pageListPessoasServices.loadOrigemEspecifica();

    if (!error && response) {
      setOrigensEspecificas(response);
    }
  }

  async function loadMotivos() {
    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.loadMotivos();

    if (!error && response) {
      setMotivos(response);
    }
    removeLoading();
  }

  function setValueCampo(e: any) {
    let value = e.value;

    if (selectedFilter === 'CPF/CNPJ') {
      const { valueModify } = MaskCPFeCNPJ(value);
      value = valueModify;
    }

    setValueInput(value);
  }

  function setPagination(page: number, itemsPage: number) {
    if (itemsPerPage !== itemsPage) {
      page = 1;
    }

    setItemsPerPage(itemsPage);

    if (tipoFiltroSelected === 'Detalhada') {
      onChangeDetalhada(page, itemsPage);
      return;
    }

    onChangeSubmit(page, itemsPage, false);
  }

  async function onChangeSubmit(
    page: number,
    itemsPerPage: number,
    semFiltro: boolean,
  ) {
    setLoading(true);
    setPage(page);

    let data = {
      id:
        valueInput !== '' && !semFiltro && selectedFilter === 'ID'
          ? parseInt(valueInput)
          : 0,
      sara_id:
        valueInput !== '' && !semFiltro && selectedFilter === 'ID AFIPE'
          ? parseInt(valueInput)
          : 0,
      id_perfil:
        valueInput !== '' && !semFiltro && selectedFilter === 'ID Perfil'
          ? parseInt(valueInput)
          : 0,
      nome: selectedFilter === 'Nome' ? valueInput : '',
      cpf: selectedFilter === 'CPF/CNPJ' ? valueInput : '',
      email: selectedFilter === 'E-mail' ? valueInput : '',
      telefone: selectedFilter === 'Telefone' ? valueInput : '',
      page_number: page,
      page_size: itemsPerPage,
    };

    const pageListPessoasServices = new PageListPessoasServices();
    const { error, response } = await pageListPessoasServices.loadPessoas({
      data,
    });
    setValuesOrError(error, response);
  }

  async function onChangeDebounce(
    page: number,
    itemsPerPage: number,
    semFiltro: boolean,
    value: string,
  ) {
    setLoading(true);
    setPage(page);

    let data = {
      id:
        value !== '' && !semFiltro && selectedFilter === 'ID'
          ? parseInt(value)
          : 0,
      sara_id:
        value !== '' && !semFiltro && selectedFilter === 'ID AFIPE'
          ? parseInt(value)
          : 0,
      id_perfil:
        value !== '' && !semFiltro && selectedFilter === 'ID Perfil'
          ? parseInt(value)
          : 0,
      nome: selectedFilter === 'Nome' ? value : '',
      cpf: selectedFilter === 'CPF/CNPJ' ? value : '',
      email: selectedFilter === 'E-mail' ? value : '',
      telefone: selectedFilter === 'Telefone' ? value : '',
      page_number: page,
      page_size: itemsPerPage,
    };

    const pageListPessoasServices = new PageListPessoasServices();
    const { error, response } = await pageListPessoasServices.loadPessoas({
      data,
    });
    setValuesOrError(error, response);
  }
  const debouncedChange = useDebounce(onChangeDebounce, 1000);

  const firstLoad = useCallback(async () => {
    setLoading(true);

    let data = {
      id: 0,
      sara_id: 0,
      id_perfil: 0,
      nome: '',
      cpf: '',
      email: '',
      telefone: '',
      page_number: 1,
      page_size: 10,
    };

    const pageListPessoasServices = new PageListPessoasServices();
    const { error, response } = await pageListPessoasServices.loadPessoas({
      data,
    });
    setValuesOrError(error, response);
  }, []);

  useEffect(() => {
    firstLoad();
    loadFormaContribuicao();
    loadTiposPerfil();
    loadOrigensEspecificas();
    loadMotivos();
  }, [firstLoad]);

  const onChangeDates = async (dates: any) => {
    SetPerido(dates);
  };

  const onChangeDetalhada = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaDetalhada({
      cidade_id: idCidade?.id || 0,
      data_final: periodo[1],
      data_inicial: periodo[0],
      estado_sigla: estado,
      forma_contribuicao_id: idFormaContribuicao,
      motivo_id: idMotivo,
      origem_especifica_id: idOrigemEspecifica,
      tipo_perfil_id: idTipoPerfil,
      pageNumber: page,
      pageSize: itemsPage,
    });

    setValuesOrError(error, response);
  };

  useEffect(() => {
    const getCidades = async () => {
      const pageListPessoasServices = new PageListPessoasServices();
      const getCidadesByUFResponse =
        await pageListPessoasServices.getCidadesByUF(estado);
      if (!getCidadesByUFResponse.error) {
        setCidades(getCidadesByUFResponse.response);
      }
    };

    if (estado !== '') {
      getCidades();
    }
    window.document.title = 'CRM - Lista de Cadastros';
  }, [estado]);

  const handleOpen = () => {
    setFiltros(!filtros);
  };

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM SOCIALLIS') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <Grid container alignItems="center" item lg={9} xs={12}>
      <Grid item md={12} xs={12} lg={12}>
        <TitlePage style={{ margin: 5, padding: 5 }}>Cadastros</TitlePage>
      </Grid>
      <Grid
        item
        md={2}
        xs={12}
        lg={2}
        style={{
          alignSelf: 'flex-end',
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10,
        }}
      >
        <Tag color={'default'}>Consulta</Tag>
      </Grid>
      <Grid
        item
        md={2}
        lg={2}
        xs={12}
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          alignSelf: 'flex-end',
          paddingTop: 10,
        }}
      >
        <Button
          color="secondary"
          fullWidth
          style={{
            height: 46,
          }}
          onClick={handleOpen}
        >
          <TuneOutlined style={{ marginRight: 10 }} /> Filtros
        </Button>
      </Grid>

      <Filtros abrir={filtros} openMenu={handleOpen}>
        <Grid
          item
          md={12}
          lg={12}
          xs={12}
          style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
        >
          <Form className="teste2 " layout={'vertical'}>
            <Form.Item
              label={<label style={{ color: '#fff' }}>Tipo de Filtro</label>}
            >
              <SelectAntd
                style={{ width: '100%' }}
                placeholder="Tipo de Filtro"
                onChange={(e: string) => {
                  if (e === 'Detalhada' || e === 'Simples') {
                    setTipoFiltroSelected(e);
                  }
                }}
              >
                {tiposFiltros.map((option, index) => (
                  <SelectAntd.Option key={option} value={option}>
                    {option}
                  </SelectAntd.Option>
                ))}
              </SelectAntd>
            </Form.Item>

            <InvisibleContent visible={tipoFiltroSelected === 'Simples'}>
              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={<label style={{ color: '#fff' }}>Filtros</label>}
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Filtros"
                    onChange={(e: string) => {
                      setValueInput('');
                      setSelectedFilter(e);
                      if (e === 'Sem filtro') {
                        debouncedChange(1, itemsPerPage, false, '');
                      }
                    }}
                  >
                    {optionsFilter.map((option, index) => (
                      <SelectAntd.Option key={option} value={option}>
                        {option}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <InvisibleContent
                visible={
                  selectedFilter !== optionsFilter[optionsFilter.length - 1]
                }
              >
                <Grid item md={12} lg={12} xs={12}>
                  <Form.Item
                    label={<label style={{ color: '#fff' }}>Buscar por:</label>}
                  >
                    <InvisibleContent visible = {selectedFilter === 'Telefone'}>
                      <Input
                        placeholder="Buscar por:"
                        name={selectedFilter}
                        value={valueInput}
                        type={
                          selectedFilter === 'ID' || selectedFilter === 'ID AFIPE' || selectedFilter === 'ID Perfil'
                            ? 'number'
                            : 'text'
                        }
                        onChange={e => {
                          setValueCampo(e.target);
                          debouncedChange(1, itemsPerPage, false, e.target.value);
                        }}
                        maxLength={14}
                      />

                    </InvisibleContent>

                    <InvisibleContent visible = {selectedFilter !== 'Telefone'}>
                      <Input
                        placeholder="Buscar por:"
                        name={selectedFilter}
                        value={valueInput}
                        type={
                          selectedFilter === 'ID' || selectedFilter === 'ID AFIPE' || selectedFilter === 'ID Perfil'
                            ? 'number'
                            : 'text'
                        }
                        onChange={e => {
                          setValueCampo(e.target);
                          debouncedChange(1, itemsPerPage, false, e.target.value);
                        }}
                      />

                    </InvisibleContent>

                  </Form.Item>
                </Grid>
              </InvisibleContent>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Detalhada'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>
              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>
                      Forma de Contribuição
                    </label>
                  }
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Forma de Contribuição"
                    onChange={(id: number) => {
                      setIdFormaContribuicao(id);
                    }}
                  >
                    {formasContribuicao.map((option, index) => (
                      <SelectAntd.Option key={option.id} value={option.id}>
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}
                    <SelectAntd.Option value={0}>Sem filtro </SelectAntd.Option>
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>Tipo de Perfil</label>
                  }
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Tipo de Perfil"
                    onChange={(id: number) => {
                      setIdTipoPerfil(id);
                    }}
                  >
                    {tiposPerfil.map((option, index) => (
                      <SelectAntd.Option key={option.id} value={option.id}>
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}
                    <SelectAntd.Option value={0}>Sem filtro</SelectAntd.Option>
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>Origem específica</label>
                  }
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Origem específica"
                    onChange={(id: number) => {
                      setIdOrigemEspecifica(id);
                    }}
                  >
                    {origensEspecificas.map((option, index) => (
                      <SelectAntd.Option key={option.id} value={option.id}>
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}
                    <SelectAntd.Option value={0}>Sem filtro</SelectAntd.Option>
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={<label style={{ color: '#fff' }}>Motivo</label>}
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Motivo"
                    onChange={(id: number) => {
                      setIdMotivo(id);
                    }}
                  >
                    {motivos.map((option, index) => (
                      <SelectAntd.Option key={option.id} value={option.id}>
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}
                    <SelectAntd.Option value={0}>Sem filtro</SelectAntd.Option>
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid item md={12} lg={12} xs={12}>
                <Form
                  form={formRef}
                  size="middle"
                  layout="vertical"
                  scrollToFirstError={true}
                  labelAlign="left"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                >
                  <Grid container>
                    <Grid item md={3} lg={3} xs={12}>
                      <Form.Item
                        label={<label style={{ color: '#fff' }}>Estado</label>}
                      >
                        <SelectAntd
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Estado"
                          onChange={(estadoSelected: string) => {
                            setEstado(estadoSelected);
                          }}
                        >
                          {UFs.UF.map((option, index) => (
                            <SelectAntd.Option
                              key={option.label}
                              value={option.label}
                            >
                              {option.label}
                            </SelectAntd.Option>
                          ))}
                          <SelectAntd.Option value={''}>
                            Sem filtro
                          </SelectAntd.Option>
                        </SelectAntd>
                      </Form.Item>
                    </Grid>

                    <InvisibleContent visible={estado !== ''}>
                      <Grid item md={9} lg={9} xs={12}>
                        <Form.Item
                          label={
                            <label style={{ color: '#fff' }}>Cidade</label>
                          }
                        >
                          <InputCidades
                            ufProps={estado}
                            notUseLabel={true}
                            notUseFeedback={true}
                          />
                        </Form.Item>
                      </Grid>
                    </InvisibleContent>
                  </Grid>
                </Form>
              </Grid>
              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeDetalhada(1, itemsPerPage);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>
          </Form>
        </Grid>
      </Filtros>
      <Grid container>
        <Grid item xs={12} lg={12} style={{ padding: 10 }}>
          <TableActions
            keys={['id', 'documento', 'nome', 'data_cadastro']}
            FieldsBold={['documento']}
            loading={loading}
            columns={[columnsLabel]}
            data={data}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            pageCurrent={page}
            fetchData={setPagination}
            totalItens={totalItens}
            Actions={[
              {
                Button: (row: IPessoa) => {
                  return (
                    <IconButton
                      onClick={() => window.open(`/cadastro/${row.id}/`)}
                    >
                      <Visibility />
                    </IconButton>
                  );
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListPessoas;
