import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, IconButton } from '@material-ui/core';

import TableCustomKeysNoPagination from '@src/Pages/Private/Cliente/Admin/DetalhesHistoricoRemessa/components/table';

import { TitlePage, SubTitleBold } from '@styles/styles.global';
import Services, { IDetalhesDebitoTable, IDetalhesDebito } from './services';
import { Form, Input, Spin, Radio } from 'antd';

import { useAuth } from '@hooks/AuthenticateContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DetalhesHistoricoRemessa: React.FC = () => {
  const query = useQuery();

  const [arquivo, setArquivo] = useState('');
  const [arquivo2, setArquivo2] = useState('');
  const [arquivo3, setArquivo3] = useState('');

  const [idArquivo, setIdArquivo] = useState('');

  const [convenioDescricao, setConvenioDescricao] = useState('');
  const [loading, setLoading] = useState(false);
  const [detalhesDebito, setDetalhesDebito] = useState<IDetalhesDebitoTable[]>(
    [],
  );

  const [detalhe, setDetalhe] = useState<IDetalhesDebitoTable>({} as IDetalhesDebitoTable);


  const handleDetalhesDebito = async (
    arquivoId:  number,
    convenio: string | number,
  ) => {
    //const dataSplit = dataSelecionada.split('/');

    const service = new Services();

    const { error, response } = await service.DetalhesDebito(
      1,
      1,
      arquivoId,
      convenio,
      // dataSelecionada,
    );

    if (!error && response) {
      setDetalhesDebito(response);
    }
  };

  const handleArquivoRemessa = async (boletoId: string) => {
    const services = new Services();

    const { error, response } = await services.arquivoRemessa(boletoId);

    if (!error && response?.link_remessa) {
      window.open(response?.link_remessa);
    } else {
      console.log(error);
    }

  };

  useEffect(() => {
    const getParams = query.get('params');
    if (getParams) {
      const { arquivoId, convenio } = JSON.parse(getParams);
      console.log({
        arquivoId,
        convenio,
      });

      setConvenioDescricao(convenio.descricao);
      setArquivo(arquivoId.nome_arquivo);
      setArquivo2(arquivoId.arquivoId);
      setArquivo3(arquivoId.usuario_cadastro);

      handleDetalhesDebito(arquivoId.arquivoId, convenio.id);
    }
    window.document.title = 'CRM - Detalhes Histórico Remessa'
  }, []);

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM SOCIALLIS') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justify="flex-start"
      >
        <Grid container item sm={12}>
          <Grid item sm={6} xs={12} style={{ alignContent: 'center', paddingBottom: 30, }} >
            <TitlePage style={{ marginRight: 20, paddingTop: 2 }}>
              Detalhes de remessa{' '}
            </TitlePage>
          </Grid>

          <Grid container item alignContent="center" sm={6} >
            <Form
              size="middle"
              layout="inline"
              labelAlign="left"
            >
              <Grid item sm={3} xs={12}>
                <Form.Item
                  label="ID: "
                >
                  <p style={{ fontSize: 12, fontWeight: 'bold' }}> {arquivo2}</p>
                </Form.Item>
              </Grid>

              <Grid item sm={8} xs={12}>
                <Form.Item
                  label="Gerada por: "
                >
                  <p style={{ fontSize: 12, fontWeight: 'bold' }}> {arquivo3}</p>
                </Form.Item>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sm={10} xs={12}>
        <Grid item sm={4} xs={12} style={{ padding: 5,}} >
          <p style={{ fontSize: 16, fontWeight: 'bold' }}> Arquivo: {arquivo}</p>
        </Grid>

        <Grid item sm={5} xs={12}  style={{ padding: 5,}} >
          <p style={{ fontSize: 16, fontWeight: 'bold' }}> Convenio : {convenioDescricao} </p>
        </Grid>

      </Grid>

      <Grid container>
      <Grid item lg={12} xs={12}>
          <TableCustomKeysNoPagination
            data={detalhesDebito}
            keys={['vencimento', 'id_pessoa','documento', 'nome', 'tipo_perfil', 'valor', 'link_remessa']}
            loading={loading}
            columns={[
              {
                vencimento: 'Vencimento',
                id_pessoa: 'ID Pessoa',
                documento: 'Documento',
                nome: 'Nome',
                tipo_perfil: 'Tipo perfil',
                valor: 'Valor',
                link_remessa: 'Link',
              },
            ]}
            handleDownloadArquivoGrafica={(boletoId: string) => handleArquivoRemessa(boletoId)}
            Actions={[ ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DetalhesHistoricoRemessa;
