import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';

import { Visibility } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Switch, Select as SelectAntd, Spin, Radio } from 'antd';

import { TitlePage } from '@styles/styles.global';
import Tag from '@components/Shared/Tag';
import TableActions from '@components/Shared/Tables/TableActions';
import Modal from '@components/Shared/Modal/ModalPure';
import ButtonComponent from '@components/Shared/Buttons/Button';

import { MaskCPFeCNPJ, isReal, ClearString } from '@utils/Masks';

import Services, {
  IEstrategiaPerfis,
  IResponseGetEstrategiaAcoesByID,
  IEstrategiasAcoes,
  IConvenio,
} from './services';
import { format, addDays } from 'date-fns';

import { useLoading } from '@hooks/LoadingContext';

import { useAuth } from '@hooks/AuthenticateContext';

const columnsLabel = {
  id: 'ID',
  documento: 'CPF/CNPJ',
  nome: 'Nome',
  perfil: 'Perfil',
};

const DATA = addDays(new Date(), 1).toISOString().split('T')[0];

const ListEstrategias: React.FC = () => {
  const { removeLoading, setValues } = useLoading();
  const history = useHistory();
  const { estrategiaID }: { estrategiaID: string } = useParams();
  const [formRefStep1] = Form.useForm();
  const [formRefStep2] = Form.useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IEstrategiaPerfis[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItens, setTotalItems] = useState(0);
  const [convenios, setConvenios] = useState<IConvenio[]>([]);

  // const [bancos, setBancos] = useState<IBancos[]>([]);
  const [tipoAcoes, setTipoAcoes] = useState<IResponseGetEstrategiaAcoesByID[]>(
    [],
  );
  const [acoesSelected, setAcoesSelected] = useState<IEstrategiasAcoes[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [gerarBoleto, setGerarBoleto] = useState(false);

  const [confirmationStep1Mala, setConfirmationStep1Mala] = useState(false);
  const [stateStep1Mala, setStateStep1Mala] = useState({
    gerar_boleto: '',
    tarefa_id: '',
    tipo_acao: '',
  });
  const [campanhaID, setCampanhaID] = useState(0);
  const [descricaoEstrategia, setDescricaoEstrategia] = useState('');
  const [nomeEstrategia, setNomeEstrategia] = useState('');
  const [nomeCampanha, setNomeCampanha] = useState('');

  const [valorBoletoEdit, setValorBoletoEdit] = useState(false);

  const [tipoEnvio, setTipoEnvio] = useState({
    id: '',
    descricao: '',
  });

  const [tarefaSelected, setTarefaSelected] = useState({
    id: '',
    descricao: '',
  });

  const [loadingHandle, setLoadingHandle] = useState(false);

  // const loadBancos = useCallback(async () => {
  //   const services = new Services();

  //   const { error, response } = await services.getBancos();

  //   if (!error && response) {
  //     setBancos(response);
  //   }
  // }, []);
  const loadCampanhaNome = useCallback(async (campanha_id: number) => {
    const services = new Services();

    const { error, response } = await services.getCampanhas({
      campanha_id: campanha_id,
    });

    if (!error && response) {
      setNomeCampanha(response.descricao);
    }
    removeLoading();
  }, []);

  const loadConvenios = async (): Promise<number> => {
    const services = new Services();
    const { error, response } = await services.GetConvenios();

    if (!error && response) {
      setConvenios(response);

      // formRef.setFieldsValue({ convenio: response[0].id });

      return response[0].id;
    } else {
      return 0;
    }
  };

  const loadTipoAcoes = useCallback(async () => {
    setValues(true);
    const services = new Services();

    const { error, response } = await services.getEstrategiaAcoesByID(
      estrategiaID,
    );

    if (!error && response) {
      setTipoAcoes(response);
    }
    removeLoading();
  }, []);

  const onChangeSelectAcoes = (id: number) => {
    const tipoAcaoSelected = tipoAcoes.filter(
      tipoacao => tipoacao.tipo_acao_id == id.toString(),
    );

    formRefStep1.setFieldsValue({
      acao_id: null,
      tarefa_id: null,
    });

    if (tipoAcaoSelected.length > 0) {
      setAcoesSelected(tipoAcaoSelected[0].estrategias_acoes);
    }
  };

  const onChangeTarefas = (id: number) => {
    const acaoSelected = acoesSelected.filter(acao => acao.id == id.toString());

    formRefStep1.setFieldsValue({
      acao_id: null,
    });

    if (acoesSelected.length > 0) {
      setTarefaSelected({
        descricao: acaoSelected[0].acao_descricao,
        id: acaoSelected[0].acao_id,
      });

      formRefStep1.setFieldsValue({
        gerar_boleto: acaoSelected[0].gerar_boleto,
      });
      setGerarBoleto(acaoSelected[0].gerar_boleto);
    }
  };

  const setValuesOrError = (error: boolean, response: any) => {
    if (!error && response) {
      setCampanhaID(response.modelo.campanha_id);
      setDescricaoEstrategia(response.modelo.descricao);
      setNomeEstrategia(response.modelo.nome);
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      let modelo: any = [];

      response.modelo.estrategia_perfis.map((item: IEstrategiaPerfis) => {
        modelo.push({
          id: item.perfil.pessoa.id,
          documento: MaskCPFeCNPJ(item.perfil.pessoa.documento).valueModify,
          nome: item.perfil.pessoa.nome,
          perfil: item.perfil.nome,
        });
      });

      setData(modelo);
      loadCampanhaNome(response.modelo.campanha_id);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const loadEstrategias = useCallback(
    async (page: number, itemsPerPage: number) => {
      setLoading(true);

      const services = new Services();
      const { error, response } = await services.getEstrategiasPerfil({
        pageNumber: page,
        pageSize: itemsPerPage,
        estrategiaId: estrategiaID,
      });
      setValuesOrError(error, response);
    },
    [],
  );

  function setPagination(page: number, itemsPage: number) {
    if (itemsPerPage !== itemsPage) {
      page = 1;
    }

    setPage(page);
    setItemsPerPage(itemsPage);

    loadEstrategias(page, itemsPage);
  }

  useEffect(() => {
    loadEstrategias(page, itemsPerPage);
    loadTipoAcoes();
    loadConvenios();
    // loadBancos();

    window.document.title = 'CRM - Detalhes Estratégia';
  }, []);

  const confirmationFormStep1Mala = async (values: any) => {
    if (!gerarBoleto) {
      const service = new Services();
      console.log(values);
      const { error } = await service.clienteConfiguracaoEstrategiaAcao({
        banco_id: '1',
        estrategia_acao_id: values.tarefa_id,
        valor_boletos: '1',
        vencimento: new Date().toISOString(),
        valor_padrao: true,
      });

      if (error) {
        console.log('Error');
      } else {
        history.push('/envios');
      }
      return;
    }
    setConfirmationStep1Mala(true);
    setStateStep1Mala(values);
  };

  const confirmationFormStep2Mala = async (values: any) => {
    setLoadingHandle(true);
    const service = new Services();

    console.log(
      stateStep1Mala.tarefa_id,
      values.valor_boleto,
      values.valor_padrao,
      values.convenio,
    );

    const { error } = await service.clienteConfiguracaoEstrategiaAcao({
      banco_id: '1',
      estrategia_acao_id: stateStep1Mala.tarefa_id,
      valor_boletos:
        values.valor_boleto === undefined
          ? 0.01
          : ClearString(values.valor_boleto) / 100,
      vencimento: values.vencimento,
      valor_padrao: values.valor_padrao,
      convenio_id: values.convenio,
    });

    if (error) {
      setLoadingHandle(false);
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    } else {
      setLoadingHandle(false);
      enqueueSnackbar('Criado sucesso', {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      history.push('/envios');
    }
  };

  const finishEnvioEmail = async (values: any) => {
    const service = new Services();

    const { error } = await service.clienteConfiguracaoEstrategiaAcaoEmail({
      estrategia_acao_id: values.tarefa_id,
    });

    if (error) {
      setLoadingHandle(false);
      enqueueSnackbar("Realize a geração dos PDF's/ Boletos primeiro", {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    } else {
      setLoadingHandle(false);
      enqueueSnackbar('Criado sucesso', {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      history.push('/envios');
    }
    return;
  };

  const finishEnvioGrafica = async (values: any) => {
    const service = new Services();

    const { error } = await service.clienteConfiguracaoEstrategiaAcaoGrafica({
      estrategia_acao_id: values.tarefa_id,
    });

    if (error) {
      setLoadingHandle(false);
      enqueueSnackbar('Configure a impressão local primeiro', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    } else {
      setLoadingHandle(false);
      enqueueSnackbar('Criado sucesso', {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      history.push('/envios');
    }
    return;
  };

  const finishEmailMarketing = async (values: any) => {
    const service = new Services();
    const { error } = await service.clienteConfiguracaoEstrategiaAcaoEmail({
      estrategia_acao_id: values.tarefa_id,
    });

    if (error) {
      console.log('Error');
    } else {
      history.push('/envios');
    }
    return;
  };

  const cancelSteps = () => {
    setConfirmationStep1Mala(false);
  };

  const handleAcao = (id: number) => {
    const tipoAcoesSelected = tipoAcoes.filter(
      acao => acao.tipo_acao_id == id.toString(),
    );

    if (tipoAcoesSelected.length > 0) {
      setTipoEnvio({
        descricao: tipoAcoesSelected[0].tipo_acao_descricao,
        id: tipoAcoesSelected[0].tipo_acao_id,
      });
    }
  };

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM SOCIALLIS') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  const RenderBodyDefault = () => {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item lg={2}>
            <TitlePage style={{ margin: 0 }}>Ação</TitlePage>
          </Grid>
          <Grid item lg={4} justify="center" style={{ fontSize: 15 }}>
            <Tag color={'default'}> {nomeCampanha}</Tag>
          </Grid>
          <Grid
            item
            lg={2}
            justify="center"
            style={{ fontSize: 15, textAlign: 'center' }}
          >
            ID: {estrategiaID}
          </Grid>
          <Grid item lg={4} style={{ fontSize: 15, fontWeight: 'bold' }}>
            {nomeEstrategia}
          </Grid>
          <Grid item lg={12} style={{ paddingTop: 10 }}>
            Quantidade de cadastros:{' '}
            <span style={{ fontWeight: 'bold' }}>{totalItens}</span>
          </Grid>
        </Grid>
        <Form
          form={formRefStep1}
          size="middle"
          layout="vertical"
          scrollToFirstError={true}
          onFinish={() => {}}
          labelAlign="left"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          <Grid container>
            <Grid item lg={6} style={{ padding: 5 }}>
              <Form.Item
                name="tipo_acao"
                label="Selecionar ação"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Selecione uma ação',
                  },
                ]}
              >
                <SelectAntd
                  placeholder="Selecionar ação"
                  onChange={(e: number) => {
                    onChangeSelectAcoes(e);
                    handleAcao(e);
                  }}
                >
                  {tipoAcoes.map(item => (
                    <SelectAntd.Option
                      key={item.tipo_acao_id}
                      value={item.tipo_acao_id}
                    >
                      {item.tipo_acao_descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>
            <Grid item lg={6} style={{ padding: 5 }}>
              <Form.Item
                name="tarefa_id"
                label="Selecionar tarefa "
                hasFeedback
                rules={[
                  {
                    required: !(acoesSelected.length <= 0),
                    message: 'Selecione uma tarefa',
                  },
                ]}
              >
                <SelectAntd
                  placeholder="Selecionar tarefa"
                  disabled={acoesSelected.length <= 0}
                  onChange={(e: number) => onChangeTarefas(e)}
                >
                  {acoesSelected.map(item => (
                    <SelectAntd.Option
                      key={item.id}
                      value={item.id}
                      disabled={false}
                    >
                      {item.acao_descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <Grid
              container
              justify="center"
              alignItems="center"
              item
              lg={6}
              style={{ padding: 5 }}
            >
              <Form.Item name="gerar_boleto" label="Gerar boleto">
                <Switch disabled={true} checked={gerarBoleto} />
              </Form.Item>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              item
              lg={6}
              style={{ padding: 5 }}
            >
              <ButtonComponent color="secondary" fullWidth type="submit" disabled={loadingHandle}>
                Avançar
              </ButtonComponent>
            </Grid>
          </Grid>
        </Form>
      </>
    );
  };

  const RenderBodyMala = () => {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item lg={2}>
            <TitlePage style={{ margin: 0 }}>Mala</TitlePage>
          </Grid>
          <Grid item lg={4} justify="center" style={{ fontSize: 15 }}>
            <Tag color={'default'}> {nomeCampanha}</Tag>
          </Grid>
          <Grid
            item
            lg={2}
            justify="center"
            style={{ fontSize: 15, textAlign: 'center' }}
          >
            ID: {estrategiaID}
          </Grid>
          <Grid item lg={4} style={{ fontSize: 15, fontWeight: 'bold' }}>
            {nomeEstrategia}
          </Grid>
          <Grid item lg={12} style={{ paddingTop: 10 }}>
            Quantidade de cadastros:{' '}
            <span style={{ fontWeight: 'bold' }}>{totalItens}</span>
          </Grid>
        </Grid>
        {!confirmationStep1Mala && (
          <Form
            form={formRefStep1}
            size="middle"
            layout="vertical"
            scrollToFirstError={true}
            onFinish={values => {
              if (
                tarefaSelected.descricao.toLocaleLowerCase() ===
                'gerar boletos e/ou cartas'
              ) {
                confirmationFormStep1Mala(values);
              } else if (
                tarefaSelected.descricao.toLowerCase() ===
                'envio por e-mail de mala'
              ) {
                finishEnvioEmail(values);
              } else if (
                tarefaSelected.descricao.toLowerCase() ===
                'impressão em gráfica de mala'
              ) {
                finishEnvioGrafica(values);
              }
            }}
            labelAlign="left"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Grid container>
              <Grid item lg={6} style={{ padding: 5 }}>
                <Form.Item
                  name="tipo_acao"
                  label="Selecionar ação"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Selecione uma ação',
                    },
                  ]}
                >
                  <SelectAntd
                    placeholder="Selecionar ação"
                    onChange={(e: number) => {
                      onChangeSelectAcoes(e);
                      handleAcao(e);
                    }}
                  >
                    {tipoAcoes.map(item => (
                      <SelectAntd.Option
                        key={item.tipo_acao_id}
                        value={item.tipo_acao_id}
                      >
                        {item.tipo_acao_descricao}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>
              <Grid item lg={6} style={{ padding: 5 }}>
                <Form.Item
                  name="tarefa_id"
                  label="Selecionar tarefa "
                  hasFeedback
                  rules={[
                    {
                      required: !(acoesSelected.length <= 0),
                      message: 'Selecione uma tarefa',
                    },
                  ]}
                >
                  <SelectAntd
                    placeholder="Selecionar tarefa"
                    disabled={acoesSelected.length <= 0}
                    onChange={(e: number) => onChangeTarefas(e)}
                  >
                    {/*  2 - liberar para cliente configurar / 3 - para enviar */}

                    {acoesSelected.map(item => (
                      <SelectAntd.Option
                        key={item.id}
                        value={item.id}
                        disabled={item.status !== 2}
                      >
                        {item.acao_descricao}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid
                container
                justify="center"
                alignItems="center"
                item
                lg={6}
                style={{ padding: 5 }}
              >
                <Form.Item name="gerar_boleto" label="Gerar boleto">
                  <Switch disabled={true} checked={gerarBoleto} />
                </Form.Item>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                item
                lg={6}
                style={{ padding: 5 }}
              >
                <ButtonComponent
                  color="secondary"
                  fullWidth
                  type="submit"
                  loading={loadingHandle}
                  disabled={loadingHandle}
                >
                  Avançar
                </ButtonComponent>
              </Grid>
            </Grid>
          </Form>
        )}

        {confirmationStep1Mala && (
          <Form
            form={formRefStep2}
            size="middle"
            layout="vertical"
            scrollToFirstError={true}
            onFinish={confirmationFormStep2Mala}
            labelAlign="left"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              qtd_cadastros: totalItens,
              vencimento: format(addDays(new Date(), +7), 'yyyy-MM-dd'),
            }}
          >
            <Grid container>
              <Grid item lg={6} style={{ padding: 5 }}>
                <Form.Item
                  name="qtd_cadastros"
                  label="Cadastros"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Selecione uma tarefa',
                    },
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Grid>

              <Grid item lg={6} style={{ padding: 5 }}>
                <Form.Item
                  name="vencimento"
                  label="Vencimento"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Selecione um vencimento',
                    },
                  ]}
                >
                  <Input type="date" min={DATA}
/>
                </Form.Item>
              </Grid>

              <Grid item lg={6} style={{ padding: 5 }}>
                <Form.Item
                  name="valor_padrao"
                  label="Valor"
                  rules={[
                    {
                      required: true,
                      message: 'Selecione um vencimento',
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={e => {
                      setValorBoletoEdit(e.target.value);
                    }}
                  >
                    <Radio value={true}>Valor padrão</Radio>
                    <Radio value={false}>Valor personalizado</Radio>
                  </Radio.Group>
                </Form.Item>
              </Grid>

              <Grid item lg={6} style={{ padding: 5 }}>
                <Form.Item
                  name="valor_boleto"
                  label="Valor"
                  rules={[
                      {
                        required: true,
                        message: 'Selecione um valor',
                      },
                    ]}
                    >
                  <Input
                    disabled={valorBoletoEdit}
                    onChange={(e: any) => {
                      formRefStep2.setFieldsValue({
                        valor_boleto: (e.target.value),
                      });
                    }}
                  />
                </Form.Item>
              </Grid>

              <Grid item lg={12} style={{ padding: 5 }}>
                <Form.Item name="convenio">
                  <SelectAntd style={{ width: '100%' }} placeholder="Banco">
                    {convenios.map((option, index) => (
                      <SelectAntd.Option key={option.id} value={option.id}>
                        {option.descricao + ' - ' + option.numero_convenio}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid
                container
                justify="center"
                alignItems="center"
                item
                lg={6}
                style={{ padding: 5 }}
              >
                <ButtonComponent
                  color="default"
                  fullWidth
                  onClick={cancelSteps}
                >
                  Voltar
                </ButtonComponent>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                item
                lg={6}
                style={{ padding: 5 }}
              >
                <ButtonComponent
                  color="secondary"
                  fullWidth
                  type="submit"
                  loading={loadingHandle}
                  disabled={loadingHandle}
                >
                  Concluir
                </ButtonComponent>
              </Grid>
            </Grid>
          </Form>
        )}
      </>
    );
  };

  const RenderBodyEmailMarketing = () => {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item lg={2}>
            <TitlePage style={{ margin: 0 }}>E-mail Marketing</TitlePage>
          </Grid>
          <Grid item lg={4} justify="center" style={{ fontSize: 15 }}>
            <Tag color={'default'}> {nomeCampanha}</Tag>
          </Grid>
          <Grid
            item
            lg={2}
            justify="center"
            style={{ fontSize: 15, textAlign: 'center' }}
          >
            ID: {estrategiaID}
          </Grid>
          <Grid item lg={4} style={{ fontSize: 15, fontWeight: 'bold' }}>
            {nomeEstrategia}
          </Grid>
          <Grid item lg={12} style={{ paddingTop: 10 }}>
            Quantidade de cadastros:{' '}
            <span style={{ fontWeight: 'bold' }}>{totalItens}</span>
          </Grid>
        </Grid>
        <Form
          form={formRefStep1}
          size="middle"
          layout="vertical"
          scrollToFirstError={true}
          onFinish={finishEmailMarketing}
          labelAlign="left"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          <Grid container>
            <Grid item lg={6} style={{ padding: 5 }}>
              <Form.Item
                name="tipo_acao"
                label="Selecionar ação"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Selecione uma ação',
                  },
                ]}
              >
                <SelectAntd
                  placeholder="Selecionar ação"
                  onChange={(e: number) => {
                    onChangeSelectAcoes(e);
                    handleAcao(e);
                  }}
                >
                  {tipoAcoes.map(item => (
                    <SelectAntd.Option
                      key={item.tipo_acao_id}
                      value={item.tipo_acao_id}
                    >
                      {item.tipo_acao_descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <Grid item lg={6} style={{ padding: 5 }}>
              <Form.Item
                name="tarefa_id"
                label="Selecionar tarefa "
                hasFeedback
                rules={[
                  {
                    required: !(acoesSelected.length <= 0),
                    message: 'Selecione uma tarefa',
                  },
                ]}
              >
                <SelectAntd
                  placeholder="Selecionar tarefa"
                  disabled={acoesSelected.length <= 0}
                  onChange={(e: number) => onChangeTarefas(e)}
                >
                  {acoesSelected.map(item => (
                    <SelectAntd.Option key={item.id} value={item.id}>
                      {item.acao_descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <Grid
              container
              justify="center"
              alignItems="center"
              item
              lg={6}
              style={{ padding: 5 }}
            >
              <ButtonComponent color="secondary" fullWidth type="submit" disabled={loadingHandle}>
                Avançar
              </ButtonComponent>
            </Grid>
          </Grid>
        </Form>
      </>
    );
  };

  return (
    <Grid container justify="space-between">
      <Grid item md={12} xs={12}>
        <TitlePage style={{ margin: 5 }}>
          Estratégia - {nomeEstrategia}
        </TitlePage>
      </Grid>

      <Grid
        item
        md={3}
        xs={12}
        style={{
          alignSelf: 'flex-center',
          alignItems: 'flex-center',
          height: 50,
          margin: 5,
          marginTop: 10,
        }}
      >
        <Tag color={'default'}>
          ID: {estrategiaID} - {totalItens} cadastros
        </Tag>
      </Grid>

      <Grid item md={5} lg={5} xs={12} container justify="flex-end" style={{}}>
        <Grid
          item
          lg={6}
          xs={12}
          container
          justify="flex-end"
          style={{ padding: 5, marginTop: 5 }}
        >
          <ButtonComponent
            color="secondary"
            onClick={() => setOpenModal(true)}
            fullWidth
            style={{ height: 46 }}
          >
            Cadastrar uma ação
          </ButtonComponent>
          <Modal
            title=""
            size="gigante"
            openModal={openModal}
            controlModal={(value: boolean) => setOpenModal(value)}
          >
            {tipoEnvio.descricao.toLocaleLowerCase() === '' &&
              RenderBodyDefault()}
            {tipoEnvio.descricao.toLocaleLowerCase() === 'mala' &&
              RenderBodyMala()}
            {tipoEnvio.descricao.toLocaleLowerCase() === 'e-mail marketing' &&
              RenderBodyEmailMarketing()}
          </Modal>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ padding: 5 }}>
        <TableActions
          loading={loading}
          FieldsBold={['documento']}
          columns={[columnsLabel]}
          data={data}
          itemsPerPage={itemsPerPage}
          totalPages={totalPages}
          pageCurrent={page}
          fetchData={setPagination}
          Actions={[
            {
              Button: (row: any) => {
                return (
                  <IconButton
                    onClick={() => window.open(`/cadastro/${row.id}/`)}
                  >
                    <Visibility />
                  </IconButton>
                );
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default ListEstrategias;
