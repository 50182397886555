import React, { useEffect, useState } from 'react';

import * as S from './styles';
import Services, { IListaEstrategia } from './services';
import InvisibleContent from '@components/Shared/InvisibleContent';

import { useAuth } from '@hooks/AuthenticateContext';

import ServiceFooterTelefonia from '@hooks/FooterTelefonia/services';
import { useFooterTelefonia } from '@hooks/FooterTelefoniaSemWebPhone';

import { Grid } from '@material-ui/core';
import TabelaModificada from './Table';
import { TitlePage } from '@styles/styles.global';
import { useSnackbar } from 'notistack';
import Button from '@src/components/Shared/Buttons/Button';

const ListaLigacao: React.FC = () => {
  const { openModalPalitagemReceptivo } = useFooterTelefonia();

  const [listaLigacao, setListaLigacao] = useState<IListaEstrategia[]>([]);
  const [integracao, setIntegracao] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  const User = localStorage.getItem('@amex:user');

  const getListasLigacao = async () => {
    const service = new Services();
    setLoading(true);
    const { error, response } = await service.getListasLigacao();

    if (!error && response) {
      setListaLigacao(response);
      setLoading(false);
    } else {
      enqueueSnackbar('Não foi possível carregar as listas de ligação.', {
        title: 'Não foi possível carregar as listas de ligação.',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  const getIntegracao = async () => {
    let usuario = JSON.parse(User != null ? User : '{}');
    //console.log(usuario.telefonia);
    //console.log(usuario.ramal);
    await setIntegracao(usuario.telefonia);

  };

  const checkWebPhone = async () => {
    const serviceFooterTelefonia = new ServiceFooterTelefonia();
    const checkWebPhone = await serviceFooterTelefonia.CheckWebPhoneOpen();

    if (checkWebPhone) {
      window.open(
        `/webphone/receptivo`,
        'windowOpenTab',
        'height=575,width=344,scrollbars=no,resizable=0',
      );
    } else {
      enqueueSnackbar(
        'Não foi possível abrir o webphone, verifique se o mesmo está aberto.',
        {
          title:
            'Não foi possível abrir o webphone, verifique se o mesmo está aberto.',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        },
      );
    }
  };


  const teste = async () => {
    const services = new Services();

    const { error, response } = await services.teste(
     user.ramal_usuario,
     user.ramal_numero,
    );
  };

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM SOCIALLIS') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  useEffect(() => {
    getIntegracao();
    getListasLigacao();
    window.document.title = 'CRM - Lista de Ligações';

  }, []);

  return (
    <Grid container style={{ padding: 10 }}>
      <Grid item xs={12} lg={3} md={3}>
        <TitlePage>Listas de ligação</TitlePage>
      </Grid>

      <Grid item xs={12} lg={2} md={2}>
        <InvisibleContent visible={integracao == 'Zenvia'}>
          <Button
            fullWidth
            color={'secondary'}
            onClick={() => checkWebPhone()}
            style={{ height: 46 }}
          >
            Abrir Webphone
          </Button>
        </InvisibleContent>

        <InvisibleContent visible={integracao == 'Sem Integração'}>
          <Button
            fullWidth
            color={'secondary'}
            onClick={() => {
              openModalPalitagemReceptivo();
            }}
            style={{ height: 46 }}
          >
            Fazer palitagem receptiva
          </Button>
        </InvisibleContent>
      </Grid>

      <Grid item xs={12} lg={1} md={1}></Grid>

      <Grid item xs={12} lg={12} md={12} style={{ marginTop: 15 }}></Grid>
      <Grid item xs={12} sm={8} lg={8} md={8}>
        <TabelaModificada
          keys={['id', 'nome']}
          columns={[
            {
              id: 'ID',
              nome: 'Lista',
            },
          ]}
          data={listaLigacao}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default ListaLigacao;
