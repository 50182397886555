import React, { useState, useEffect } from 'react';

// import { Container } from './styles';

import TableNoPagination from '../../Tables/TableBoletoNoPagination';
import api from '@http/api';
import { Grid, IconButton } from '@material-ui/core';

import { CurrencyFormat, FormatDate } from '@utils/formats';
import { FontDownloadOutlined } from '@material-ui/icons';

import { FileDownloadOutlined } from '@mui/icons-material/';
import moment from 'moment';

import { useAuth } from '@hooks/AuthenticateContext';

interface ITest {
  Campanha: string;
  Estrategia: string;

  StatusBoleto: string;
  LinkBoleto?: string;
  DataVencimento: string;
}

interface IProps {
  idPerfil: number;
}

const TabelaBoletos: React.FC<IProps> = ({ idPerfil }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ITest[]>([]);
  const [total, setTotal] = useState<number>(0);

  const columnsLabel = {
    Campanha: 'Campanha',
    StatusBoleto: 'Status',
    DataVencimento: 'Vencimento',
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      await api
        .get(`api/Historico/GetBoletosPerfil/${idPerfil}`)
        .then(response => {
          const items = response.data;
          setTotal(response.data.total_doacoes);
          var itemData: ITest[] = [];

          items.map((item: ITest) => {
            console.log(item);
            return itemData.push({
              Campanha: item.Campanha,
              Estrategia: item.Estrategia,
              StatusBoleto: item.StatusBoleto,
              LinkBoleto: item.LinkBoleto,
              DataVencimento: item.DataVencimento,
            });
          });

          setData(itemData);
          setLoading(false);
        })
        .catch(error => {
          alert(error);
        });
    }

    loadData();
  }, [idPerfil]);

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM SOCIALLIS') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <TableNoPagination
      loading={loading}
      columns={[columnsLabel]}
      data={data}
      keys={['Campanha', 'StatusBoleto', 'DataVencimento']}
      ActionHeader="Ação"
      Actions={[
        {
          Button: (row: ITest) => {
            if (row.LinkBoleto) {
              return (
                <IconButton
                  aria-label="download"
                  onClick={() => {
                    window.open(row.LinkBoleto, '_blank');
                  }}
                >
                  <FileDownloadOutlined />
                </IconButton>
              );
            } else {
              return (
                <IconButton
                  aria-label="download"
                  onClick={() => {
                    window.open(row.LinkBoleto, '_blank');
                  }}
                  disabled
                >
                  <FontDownloadOutlined />
                </IconButton>
              );
            }
          },
        },
      ]}
    />
  );
};

export default TabelaBoletos;
