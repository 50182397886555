import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { getColors } from '@styles/colorsTheme';

import MultiProgress from 'react-multi-progress';

import { useSnackbar } from 'notistack';
import { CurrencyFormat } from '@utils/formats';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Brush,
  LabelList,
  Cell,
} from 'recharts';

import api from '@http/api';

import InvisibleContent from '@components/Shared/InvisibleContent';
import WalletBox from "@components/Shared/WalletBox2";

import {
  Grid,
  IconButton,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import { addDays } from 'date-fns';

import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  TuneOutlined,
  // CloudDownloadOutlined,
} from '@material-ui/icons';

import DoacoesServices, { IItems }  from './services';

import DatePicker from '@components/Shared/DatePicker';
import Button from '@components/Shared/Buttons/Button';
import { SubTitle, TitlePage } from '@styles/styles.global';
import { DivLoading, Content } from './styles';

// import ButtonWithIcon from '../../../components/Buttons/ButtonWithIcon';
import { CloudDownloadOutlined } from '@ant-design/icons';

import { Button as ButtonIcon } from 'antd';
import { useLoading } from '@hooks/LoadingContext';
import Filtros from '@src/components/Shared/Filtros';

import TableNoPagination from '@src/components/Shared/Tables/TableDoacaoNoPagination';
import { Select as SelectAntd, Form } from 'antd';

import { useAuth } from '@hooks/AuthenticateContext';

const formatoDeGraficos = ['Barra', 'Linha'];
const formatoDeGraficos2 = ['Barra', 'Linha', 'Tabela'];

const dados: any = [];

export interface IItemsDashboardMes {
  mes: string;
  doadores_mes: number;
  doacoes_mes: number;
  valor_doacoes_mes: number;
  cadastros_mes: number;
  percentual_doadores: number;
  media_per_capta: number;
}

interface IGraficoPeriodo {
  key: string;
  valor: string;
}

interface ITabelaMensal {
  mes: string;
  ano_atual: string;
  ano_passado: string;
  ano_retrasado: string;
}

interface IValorTotalMensal {
  janeiro: number;
  fevereiro: number;
  marco: number;
  abril: number;
  maio: number;
  junho: number;
  julho: number;
  agosto: number;
  setembro: number;
  outubro: number;
  novembro: number;
  dezembro: number;
}
const tiposFiltros = ['Doações por período', 'Comparativo doaçoes por período', 'Doações mensais e anuais'];

const pegaUltimosAnos = () => {
  const anoAtual = new Date().getFullYear();

  const anoPassado = anoAtual - 1;
  const anoRetrasado = anoAtual - 2;

  return {
    anoAtual,
    anoPassado,
    anoRetrasado,
  };
};

const ultimos14Dias = addDays(new Date(), -14);

export const colors = { blueTheme: ['#CECDE5','#C2C0DE','#B6B4D8','#AAA7D1','#9E9BCB','#8682BE','#7875AB','#6B6898','#5D5D85','#504E72','#43415F','#35344C'],};

const Graficos: React.FC = () => {
  const { user } = useAuth();
  const matches = useMediaQuery('(max-width: 600px)');
  const { setValues, removeLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const [mobile, setMobile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [comparaAnos, setComparaAnos] = useState<number[]>([
    pegaUltimosAnos().anoAtual,
  ]);
  const [indexGrafico, setIndexGrafico] = useState(0);
  const [currentGrafico, setCurrentGrafico] = useState('Barra');
  const [currentGrafico2, setCurrentGrafico2] = useState('Barra');
  const [dates, setDate] = useState([ultimos14Dias, new Date()]);
  const [filterDateSelected, setFilterDateSelected] = useState(0);
  const [graficoPeriodo, setGraficoPeriodo] = useState<IGraficoPeriodo[]>([]);
  const [graficoPeriodo2, setGraficoPeriodo2] = useState<IGraficoPeriodo[]>([]);

  const [controlBrush, setControlBrush] = useState({
    startIndex: 0,
    endIndex: 0,
  });

  const [valorTotalDoacoes, setValorTotalDoacoes] = useState<number>(0);
  const [valorTotalDoacoesAnual, setValorTotalDoacoesAnual] = useState<number>(0);
  const [metaTotalDoacoes, setMetaTotalDoacoes] = useState<number>(9000000);

  const [quantidadeDoacoes, setQuantidadeDoacoes] = useState<number>(0);
  const [mediaDoacoes, setMediaDoacoes] = useState<number>(0);

  const [data, setData] = useState<ITabelaMensal[]>([]);

  const [valorTotalMensal, setValorTotalMensal] = useState<IValorTotalMensal>({} as IValorTotalMensal);

  const ano_atual = new Date().getFullYear();
  const ano_passado = ano_atual - 1;
  const ano_retrasado = ano_atual - 2;

  const columnsLabel = {
    mes: 'MÊS',
    ano_atual:  ano_atual,
    ano_passado: ano_passado,
    ano_retrasado:ano_retrasado,
  };

  const columnsLabel2 = {
    key: 'Data',
    valor: 'Valor da doação'
  };

  const [comparacaoMes, setComparacaoMes] = useState<IItemsDashboardMes[]>([]);

  const [filtros, setFiltros] = useState(false);

  const [tipoFiltroSelected, setTipoFiltroSelected] = useState<
  'Doações por período' | 'Comparativo doaçoes por período' | 'Doações mensais e anuais'>();

  const controlaFiltroDeAnos = (anoSelecionado: number) => {
    const existe = comparaAnos?.includes(anoSelecionado);

    if (existe) {
      setComparaAnos(comparaAnos?.filter(ano => ano !== anoSelecionado));
    } else {
      setComparaAnos([...comparaAnos, anoSelecionado]);
    }
  };

  const escolheFormatoGrafico = useCallback(() => {
    let index = indexGrafico;

    index === formatoDeGraficos.length - 1 ? (index = 0) : index++;
    setIndexGrafico(index);
    setCurrentGrafico(formatoDeGraficos[index]);
  }, [indexGrafico]);

  const escolheFormatoGrafico2 = useCallback(() => {
    let index = indexGrafico;

    index === formatoDeGraficos2.length - 1 ? (index = 0) : index++;
    setIndexGrafico(index);
    setCurrentGrafico2(formatoDeGraficos2[index]);
  }, [indexGrafico]);

  const changeFilterGrafiph = useCallback((index: number) => {
    if (index === 3) {
      setCurrentGrafico('Barra');
    }
    setFilterDateSelected(index);
  }, []);

  const checkColorSelected = useCallback(
    (indexGrafico: number) => {
      return filterDateSelected === indexGrafico ? 'primary' : 'default';
    },
    [filterDateSelected],
  );

  const loadRelatorioPeriodo = async (dates: any) => {
    setLoading(true);
    setControlBrush({ endIndex: 0, startIndex: 0 });
    const doacoesServices = new DoacoesServices();
    setFilterDateSelected(1);
    setDate(dates);

    const response = await doacoesServices.loadRelatorioPeriodo({
      data_inicial: dates[0],
      data_final: dates[1],
    });


    if (!response.error && response.response) {
      setValorTotalDoacoes(response.response?.doacoes_periodo);
      setQuantidadeDoacoes(response.response.quantidade_doacoes_periodo);
      setMediaDoacoes(response.response?.media_doacoes_periodo);
      setGraficoPeriodo2(response.response?.graficoPeriodoData);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoading(false);
  };

  const loadRelatorioPeriodoRecente = async (dates: any) => {
    setLoading(true);
    setControlBrush({ endIndex: 0, startIndex: 0 });
    const doacoesServices = new DoacoesServices();
    setFilterDateSelected(5);
    setDate(dates);

    const response = await doacoesServices.loadRelatorioPeriodoRecente({
      data_inicial: dates[0],
      data_final: dates[1],
    });


    if (!response.error && response.response) {
      setValorTotalDoacoes(response.response?.doacoes_periodo);
      setQuantidadeDoacoes(response.response.quantidade_doacoes_periodo);
      setMediaDoacoes(response.response?.media_doacoes_periodo);
      setGraficoPeriodo(response.response?.graficoPeriodoData);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoading(false);
  };

  const loadRelatorioPeriodoComparacao = async (dates: any) => {
    setLoading(true);
    //setPage(page);
    setFilterDateSelected(4);
    const doacoesServices = new DoacoesServices();

    const { error, response } = await doacoesServices.loadRelatorioPeriodoComparacao({

      data_final: dates[1],
      data_inicial: dates[0],

    });

    if (!error && response) {
      dados[4] = response;
    }
      setLoading(false);

  };

  //Bloqueio inspecionar
  useEffect(() => {
    if (user.role !== 'ADM SOCIALLIS') {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'F12') {
          event.preventDefault();
          alert('F12 está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'I') {
          event.preventDefault();
          alert('Ctrl+Shift+I está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'J') {
          event.preventDefault();
          alert('Ctrl+Shift+J está desativado.');
        }
        if (event.ctrlKey && event.key === 'U') {
          event.preventDefault();
          alert('Ctrl+U está desativado.');
        }
      };

      const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        alert('O botão direito do mouse está desativado.');
      };

      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('contextmenu', handleContextMenu);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, [user.role]);

  //Iniciando os dados no grafico
 {/** useEffect(() => {
    async function loadDatas() {
      const doacoesServices = new DoacoesServices();
      setValues(true);
      setLoading(true);

      const relatorioMensalComparacaoMensal =
        await doacoesServices.GetRelatorioMensalComparacaoMensal();

      if (!relatorioMensalComparacaoMensal.error) {
        dados[2] = relatorioMensalComparacaoMensal.response;
      } else {
        enqueueSnackbar('Ocorreu um erro.', {
          title: 'Erro de autenticação',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }

      const relatorioMensalComparacaoAnual =
        await doacoesServices.GetRelatorioMensalComparacaoAnual();

      if (!relatorioMensalComparacaoAnual.error) {
        dados[3] = relatorioMensalComparacaoAnual.response;
      } else {
        enqueueSnackbar('Ocorreu um erro.', {
          title: 'Erro de autenticação',
          variant: 'error',

          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }

      removeLoading();
      setLoading(false);
    }

    loadDatas();
  }, [enqueueSnackbar]);
  */}

  async function loadData() {
    setLoading(true);
    await api
      .get('/api/Relatorio/GetRelatorioComparacaoMensal')
      .then(response => {
        const items = response.data;
        //setTotal(response.data.total_doacoes);
        var itemData: ITabelaMensal[] = [];

        items.map((item: ITabelaMensal) => {
          //console.log(item);
          return itemData.push({
            mes: item.mes,
            ano_atual: CurrencyFormat(parseFloat(item.ano_atual)),
            //ano_atual: item.ano_atual,
            ano_passado: CurrencyFormat(parseFloat(item.ano_passado)),
            ano_retrasado: CurrencyFormat(parseFloat(item.ano_retrasado)),
          });
        });

        setData(itemData);
        setLoading(false);
      })
      .catch(error => {
        //alert(error);
      });
  }

  async function loadValorMensal() {
    setLoading(true);
    await api
      .post('api/Dashboard/FindDashboardDoacao')
      .then(response => {
        setValorTotalMensal (response.data);
      })
      .catch(error => {
        //alert(error);
      });
  }

  const setValuesOrErrorDashboard = (error: boolean, response: any) => {
    if (!error && response) {

      setValorTotalDoacoesAnual(response.valor_total_doacoes);
      setComparacaoMes(response.comparacao_mes);

      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const loadTipoDashboard = async () => {
    setLoading(true);
    setFilterDateSelected(0);
    const doacoesServices = new DoacoesServices();
      const { error, response } = await doacoesServices.loadDashboardGeral();
      setValuesOrErrorDashboard(error, response);
      setLoading(false);
  };

  //Iniciando os dados no grafico
  useEffect(() => {

    ///loadRelatorioPeriodoRecente(dates);

    //loadData();
    //loadValorMensal();
    window.document.title = 'CRM - Doações';
  }, []);

  useLayoutEffect(() => {
    setMobile(matches);
  }, [matches]);

  const gerarRelatorioPDF = async () => {
    setLoadingPDF(true);
    const doacoesServices = new DoacoesServices();

    const { error, response } = await doacoesServices.GetRelatorioPDF({
      anual: {
        anoAtual: true,
        anoPassado: true,
        anoRetrasado: true,
        ativo: true,
        grafico: true,
        tabela: true,
      },
      mensal: {
        anoAtual: true,
        anoPassado: true,
        anoRetrasado: true,
        ativo: true,
        grafico: true,
        tabela: true,
      },
      entidade_id: 1,
      usuario: '',
    });

    if (!error && response) {
      enqueueSnackbar('Relatório gerado', {
        title: 'Relatório gerado',
        variant: 'success',
        action: () => {
          return (
            <a
              style={{ color: getColors()?.branco, fontStyle: 'none' }}
              href={response.responseURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Baixar PDF
            </a>
          );
        },
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingPDF(false);
    } else {
      enqueueSnackbar('Ocorreu um erro ao gerar o relatório em PDF', {
        title: 'Ocorreu um erro.',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingPDF(false);
    }
  };

  const handleOpen = () => {
    setFiltros(!filtros);
  };

  const faltam = metaTotalDoacoes - valorTotalDoacoesAnual;
  const sobra =  valorTotalDoacoesAnual - metaTotalDoacoes;

  const porcentagemMes1 = valorTotalMensal.janeiro / metaTotalDoacoes;
  const porcentagemMes2 = valorTotalMensal.fevereiro / metaTotalDoacoes;
  const porcentagemMes3 = valorTotalMensal.marco / metaTotalDoacoes;
  const porcentagemMes4 = valorTotalMensal.abril / metaTotalDoacoes;
  const porcentagemMes5 = valorTotalMensal.maio / metaTotalDoacoes;
  const porcentagemMes6 = valorTotalMensal.junho / metaTotalDoacoes;
  const porcentagemMes7 = valorTotalMensal.julho / metaTotalDoacoes;
  const porcentagemMes8 = valorTotalMensal.agosto / metaTotalDoacoes;
  const porcentagemMes9 = valorTotalMensal.setembro / metaTotalDoacoes;
  const porcentagemMes10 = valorTotalMensal.outubro / metaTotalDoacoes;
  const porcentagemMes11 = valorTotalMensal.novembro / metaTotalDoacoes;
  const porcentagemMes12 = valorTotalMensal.dezembro / metaTotalDoacoes;
  const porcentagemMeta = metaTotalDoacoes / metaTotalDoacoes;
  const porcentagemValorTotal = valorTotalDoacoesAnual / metaTotalDoacoes;
  const porcentagem =  (porcentagemValorTotal * 100).toFixed(2)


  return (
    <>
       <Grid  container item md={12} lg={12} xs={12} style={{paddingTop: 150, alignItems: 'center', justifyContent: 'center',}}>
          <p style={{ fontSize: 16, fontWeight: 'bold' }}> Seja bem-vindo(a) ao CRM - Sociallis Tecnologia</p>
       </Grid>

      {/**
      <Grid
        container
        item
        md={12}
        lg={12}
        xs={12}
      >
        <Grid
          item
          md={12}
          lg={12}
          xs={12}
          style={{paddingLeft: 10}}

         >
          <Button
            color="secondary"
            fullWidth
            style={{
              height: 40,
              width: 150,

            }}
            onClick={handleOpen}
          >
            <TuneOutlined style={{ marginRight: 10 }} /> Filtros
          </Button>
        </Grid>
      </Grid>
      <>

        <Grid
          container
          alignItems="center"
          justify={mobile ? 'center' : 'flex-start'}
        >
          <Filtros abrir={filtros} openMenu={handleOpen}>
            <Grid
              item
              md={12}
              lg={12}
              xs={12}
              style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
            >
              <Form className="teste2 " layout={'vertical'}>
                <Form.Item
                  label={<label style={{ color: '#fff' }}>Tipo de filtro</label>}
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    //placeholder="Tipo de Filtro"
                    onChange={(e: string) => {
                      if (e === 'Doações por período' || e === 'Comparativo doaçoes por período' || e === 'Doações mensais e anuais') {
                        setTipoFiltroSelected(e);
                      }
                    }}
                  >
                    {tiposFiltros.map((option, index) => (
                      <SelectAntd.Option key={option} value={option}>
                        {option}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>

                <InvisibleContent visible={tipoFiltroSelected === 'Doações por período'}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
                  >
                    <DatePicker
                      cleanable={false}
                      notUseDefaultValue={true}
                      title="Período: "
                      color={checkColorSelected(0)}
                      setValor={loadRelatorioPeriodo}
                      placement={'bottomEnd'}
                    />
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={tipoFiltroSelected === 'Comparativo doaçoes por período'}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
                  >
                    <DatePicker
                      cleanable={false}
                      notUseDefaultValue={true}
                      title="Período: "
                      color={checkColorSelected(4)}
                      setValor={loadRelatorioPeriodoComparacao}
                      placement={'bottomEnd'}
                    />
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    style={{ marginTop: 10, paddingLeft: 10 }}
                  >
                    <Grid container>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                        style={{ marginRight: 10 }}
                      >
                        <SubTitle style={{ color: getColors()?.branco }}>
                          Comparar com:{' '}
                        </SubTitle>
                      </Grid>
                      {[
                        pegaUltimosAnos().anoRetrasado,
                        pegaUltimosAnos().anoPassado,
                        pegaUltimosAnos().anoAtual,
                      ].map(item => (
                        <Grid
                          item
                          lg={4}
                          sm={4}
                          xs={4}
                          key={item}
                          style={{ paddingRight: 10 }}
                        >
                          <Button
                            key={item}
                            style={{
                              paddingLeft: 5,
                              paddingRight: 5,
                              height: '46px',
                              width: '100%',
                              marginRight: 5,
                            }}
                            loading={false}
                            onClick={() => controlaFiltroDeAnos(item)}
                            color={
                              comparaAnos.includes(item) ? 'primary' : 'default'
                            }
                          >
                            {item.toString()}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={tipoFiltroSelected === 'Doações mensais e anuais'}>
                  <Grid container style={{ marginTop: 10, padding: 0, width: '100%' }}>
                    <Grid
                      item
                      xs={6}
                      lg={6}
                      style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
                    >
                      <Button
                        style={{ height: '46px', width: '100%' }}
                        onClick={() => changeFilterGrafiph(2)}
                        loading={false}
                        color={checkColorSelected(2)}
                      >
                        Mensal
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      lg={6}
                      style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
                    >
                      <Button
                        disabledColor="default"
                        style={{ height: '46px', width: '100%' }}
                        onClick={() => changeFilterGrafiph(3)}
                        loading={false}
                        color={checkColorSelected(3)}
                      >
                        Anual
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    style={{ marginTop: 10, paddingLeft: 10 }}
                  >
                    <Grid container>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                        style={{ marginRight: 10 }}
                      >
                        <SubTitle style={{ color: getColors()?.branco }}>
                          Comparar com:{' '}
                        </SubTitle>
                      </Grid>
                      {[
                        pegaUltimosAnos().anoRetrasado,
                        pegaUltimosAnos().anoPassado,
                        pegaUltimosAnos().anoAtual,
                      ].map(item => (
                        <Grid
                          item
                          lg={4}
                          sm={4}
                          xs={4}
                          key={item}
                          style={{ paddingRight: 10 }}
                        >
                          <Button
                            key={item}
                            style={{
                              paddingLeft: 5,
                              paddingRight: 5,
                              height: '46px',
                              width: '100%',
                              marginRight: 5,
                            }}
                            loading={false}
                            onClick={() => controlaFiltroDeAnos(item)}
                            color={
                              comparaAnos.includes(item) ? 'primary' : 'default'
                            }
                          >
                            {item.toString()}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </InvisibleContent>
              </Form>
            </Grid>
          </Filtros>
        </Grid>

        <InvisibleContent visible={user.entidade_id === 7 && filterDateSelected === 0}>

          <Content style={{paddingLeft: 10, paddingRight: 10}}>

            <InvisibleContent visible={valorTotalDoacoesAnual < metaTotalDoacoes}>
              <WalletBox
                title="Meta de doação"
                color="#b0acc8"
                amount={metaTotalDoacoes}
                icon="doacao"
              />
            </InvisibleContent>

            <InvisibleContent visible={valorTotalDoacoesAnual >= metaTotalDoacoes}>
              <WalletBox
                title="Parabéns, vocês conseguiram bater a meta de 7 milhões"
                color="#b0acc8"
                amount={0}
                icon="doacao"
              />
            </InvisibleContent>

            <WalletBox
              title="Valor total das doações"
              color="#e0a8b4"
              amount={valorTotalDoacoesAnual}
              icon="totalDoacao"
            />
            <WalletBox
              title="% das doações"
              color="#8c83de"
              amount={porcentagem}
              icon="porcentagem"
            />

            <InvisibleContent visible={valorTotalDoacoesAnual < metaTotalDoacoes}>
              <WalletBox
                title="Para atingir a meta faltam,"
                color="#b25a8a"
                amount={faltam}
                icon="mediaPerCapta"
              />
            </InvisibleContent>

            <InvisibleContent visible={valorTotalDoacoesAnual >= metaTotalDoacoes}>
            <WalletBox
              title="Valor excedente da meta "
              color="#b25a8a"
              amount={sobra}
              icon="mediaPerCapta"
            />
            </InvisibleContent>
          </Content>

          <Grid container>
            <Grid
              item
              md={9}
              lg={9}
              xs={9}
              style={{
                paddingRight: 10,
                paddingLeft: 10,
                alignSelf: 'flex-end',
                paddingTop: 10,
              }}
            >

              <MultiProgress
                round={false}
                height={25}
                //width={Math.round(getPercentage14 * 100)}
                //W value={getPercentage14 * 100}
                elements={[

                  {
                    value: porcentagemMes1 * 100,
                    color: "#CECDE5",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },
                  {
                    value: porcentagemMes2 * 100,
                    color: "#C2C0DE",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },
                  {
                    value: porcentagemMes3 * 100,
                    color: "#B6B4D8",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },
                  {
                    value: porcentagemMes4 * 100,
                    color: "#AAA7D1",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },
                  {
                    value: porcentagemMes5 * 100,
                    color: "#9E9BCB",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },
                  {
                    value: porcentagemMes6 * 100,
                    color: "#8682BE",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },
                  {
                    value: porcentagemMes7 * 100,
                    color: "#7875AB",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },
                  {
                    value: porcentagemMes8 * 100,
                    color: "#6B6898",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },
                  {
                    value: porcentagemMes9 * 100,
                    color: "#5D5D85",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,

                  },
                  {
                    value: porcentagemMes10 * 100,
                    color: "#504E72",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },
                  {

                    value: porcentagemMes11 * 100,
                    color: "#43415F",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },
                  {
                    value: porcentagemMes12 * 100,
                    color: "#35344C",
                    textColor: "white",
                    //showPercentage: true,
                    fontSize: 12,
                  },


                ]}
              />
            </Grid>

            <Grid
              item
              md={1}
              lg={1}
              xs={1}
              style={{
                paddingRight: 10,
                paddingLeft: 0,
                alignSelf: 'flex-end',
                paddingTop: 10,
              }}
            >
              <p>{porcentagem}% </p>
            </Grid>
          </Grid>
        </InvisibleContent>

        <Grid container>
          <Grid
            item
            md={9}
            lg={9}
            xs={9}
            style={{
              paddingRight: 10,
              paddingLeft: 10,
              alignSelf: 'flex-end',
              paddingTop: 10,
            }}
          >
          </Grid>
        </Grid>

       // Gráficos - HEADER /
        <Grid container style={{ paddingLeft: 10, paddingRight: 10 }}>
          {loading && (
            <DivLoading container item lg={10} md={10} xs={12}>
              <CircularProgress color="secondary" size={20}></CircularProgress>
            </DivLoading>
          )}
          <Grid
            item
            lg={10}
            md={10}
            xs={12}
            style={{
              background: '#f1f1f166',
              paddingTop: 10,
              paddingBottom: 20,
              paddingRight: 20,
              marginTop: 10,
            }}
          >
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              justify="space-between"
              alignItems="center"
            >
              <Grid
                container
                item
                lg={9}
                style={{ paddingLeft: 20 }}
                justify="center"
              >
                {filterDateSelected === 1 &&(
                  <>
                    <Grid
                      item
                      lg={3}
                      md={12}
                      xs={12}
                      style={{ paddingLeft: 10 }}
                    >
                      <strong>{quantidadeDoacoes}</strong> doações no período
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={12}
                      xs={12}
                      style={{ paddingLeft: 10 }}
                    >
                      Valor médio de doação:{' '}
                      <strong>{CurrencyFormat(mediaDoacoes)}</strong>
                    </Grid>
                    <Grid
                      item
                      lg={5}
                      md={12}
                      xs={12}
                      style={{ paddingLeft: 10 }}
                    >
                      Valor total do período:{' '}
                      <strong>{CurrencyFormat(valorTotalDoacoes)} </strong>{' '}
                    </Grid>
                  </>
                )}

                {filterDateSelected === 5  && (
                  <>
                    <Grid
                      item
                      lg={3}
                      md={12}
                      xs={12}
                      style={{ paddingLeft: 10 }}
                    >
                      <strong>{quantidadeDoacoes}</strong> doações no período
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={12}
                      xs={12}
                      style={{ paddingLeft: 10 }}
                    >
                      Valor médio de doação:{' '}
                      <strong>{CurrencyFormat(mediaDoacoes)}</strong>
                    </Grid>
                    <Grid
                      item
                      lg={5}
                      md={12}
                      xs={12}
                      style={{ paddingLeft: 10 }}
                    >
                      Valor total do período:{' '}
                      <strong>{CurrencyFormat(valorTotalDoacoes)} </strong>{' '}
                    </Grid>
                  </>
                )}

              </Grid>

              {filterDateSelected === 1 &&(
                <Grid container item lg={3} xs={12} alignItems="center">
                  <Grid item xs={3}></Grid>
                  <Grid item lg={4} xs={4}>
                    <IconButton
                      onClick={escolheFormatoGrafico2}
                    >
                      <ArrowBackIosOutlined />
                    </IconButton>
                  </Grid>

                  <Grid item lg={4} xs={4}>
                    <IconButton
                      onClick={escolheFormatoGrafico2}
                    >
                      <ArrowForwardIosOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

               {filterDateSelected === 2 &&(
                <Grid container item lg={3} xs={12} alignItems="center">
                  <Grid item xs={3}></Grid>
                  <Grid item lg={4} xs={4}>
                    <IconButton
                      onClick={escolheFormatoGrafico2}
                    >
                      <ArrowBackIosOutlined />
                    </IconButton>
                  </Grid>

                  <Grid item lg={4} xs={4}>
                    <IconButton
                      onClick={escolheFormatoGrafico2}
                    >
                      <ArrowForwardIosOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

              {filterDateSelected === 3 &&(
                <Grid container item lg={3} xs={12} alignItems="center">
                  <Grid item xs={3}></Grid>
                  <Grid item lg={4} xs={4}>
                    <IconButton
                      onClick={escolheFormatoGrafico2}
                      disabled
                    >
                      <ArrowBackIosOutlined />
                    </IconButton>
                  </Grid>

                  <Grid item lg={4} xs={4}>
                    <IconButton
                      onClick={escolheFormatoGrafico2}
                      disabled
                    >
                      <ArrowForwardIosOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

              {filterDateSelected === 4 &&(
                <Grid container item lg={3} xs={12} alignItems="center">
                  <Grid item xs={3}></Grid>
                  <Grid item lg={4} xs={4}>
                    <IconButton
                      onClick={escolheFormatoGrafico2}
                      disabled
                    >
                      <ArrowBackIosOutlined />
                    </IconButton>
                  </Grid>

                  <Grid item lg={4} xs={4}>
                    <IconButton
                      onClick={escolheFormatoGrafico2}
                      disabled
                    >
                      <ArrowForwardIosOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

              {filterDateSelected === 5 &&(
                <Grid container item lg={3} xs={12} alignItems="center">
                  <Grid item xs={3}></Grid>
                  <Grid item lg={4} xs={4}>
                    <IconButton
                      onClick={escolheFormatoGrafico}
                    // disabled={filterDateSelected === 3}
                    >
                      <ArrowBackIosOutlined />
                    </IconButton>
                  </Grid>

                  <Grid item lg={4} xs={4}>
                    <IconButton
                      onClick={escolheFormatoGrafico}
                      //disabled={filterDateSelected === 3}
                    >
                      <ArrowForwardIosOutlined />

                    </IconButton>
                  </Grid>

                  // <Grid item lg={7} xs={5}>
                    <ButtonIcon
                      type="primary"
                      size="large"
                      style={{
                        background: getColors()?.azulClaro,
                        borderColor: getColors()?.azulClaro,
                        width: '97%',
                      }}
                      icon={<CloudDownloadOutlined color="#fff" />}
                      loading={loadingPDF}
                      onClick={gerarRelatorioPDF}
                    >
                      Relatório
                    </ButtonIcon>
                  </Grid> //
                  // <ButtonWithIcon onClick={gerarRelatorioPDF}>
                    <Grid container justify={'center'} alignItems="center">
                      <Grid
                        container
                        item
                        sm={3}
                        xs={3}
                        justify="center"
                        alignItems="center"
                      >
                        <CloudDownloadOutlined htmlColor="#fff" />
                      </Grid>

                      <Grid
                        container
                        item
                        sm={8}
                        xs={8}
                        justify={'flex-start'}
                        alignItems="center"
                      >
                        <p
                          style={{
                            textAlign: 'left',
                            color: getColors()?.branco,
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                        >
                          Relatório
                        </p>
                      </Grid>
                    </Grid>
                  </ButtonWithIcon> //
                </Grid>
              )}
            </Grid>

          //Gráficos //

           // Gráficos - TELA DE RESULTADO MENSAL X META - OSID //
            <InvisibleContent visible={user.entidade_id === 7}>
              {filterDateSelected === 0 && (
                <Grid container style={{ height: 400 }}>

                  {currentGrafico === 'Barra' && (
                    <>
                      <ResponsiveContainer>
                        <BarChart width={300} height={300} data={comparacaoMes}>
                          <CartesianGrid />
                          <XAxis dataKey="mes" />
                          <YAxis />
                          <Tooltip formatter={value => CurrencyFormat(value)} />
                          <Legend />
                          // <Brush
                            dataKey="key"
                            height={50}
                            travellerWidth={10}
                            startIndex={controlBrush.startIndex}
                            endIndex={controlBrush.endIndex}
                            onChange={value => setControlBrush(value)}
                            width={300}
                            stroke="#5c89a8"
                          ></Brush> //

                          <Bar dataKey="valor_doacoes_mes"  name="Doações"  fill='rgb(92, 137, 168)' >
                            {colors.blueTheme.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={colors.blueTheme[index % 20]} />
                            ))}

                          </Bar>

                        </BarChart>
                      </ResponsiveContainer>
                    </>
                  )}
                </Grid>
              )}
            </InvisibleContent>

         // Gráficos - DOAÇÕES POR PERÍODO
            {filterDateSelected === 1 && (
              <Grid container style={{ height: 400 }}>
                {currentGrafico2 === 'Linha' && (
                  <>
                    <ResponsiveContainer>
                      <LineChart width={300} height={300} data={graficoPeriodo2}>
                        <XAxis dataKey="key" />
                        <YAxis />
                        <CartesianGrid />
                        <Tooltip formatter={value => CurrencyFormat(value)} />
                        <Legend />

                        <Line
                          type="monotone"
                          dataKey="valor"
                          strokeWidth={2}
                          activeDot={{ r: 8 }}
                          stroke="rgb(99, 223, 109)"
                        />
                        //<Brush
                          dataKey="key"
                          height={50}
                          travellerWidth={10}
                          startIndex={controlBrush.startIndex}
                          endIndex={controlBrush.endIndex}
                          onChange={value => setControlBrush(value)}
                          padding={{ right: 100 }}
                          width={300}
                          stroke="#5c89a8"
                        ></Brush> ///
                      </LineChart>
                    </ResponsiveContainer>
                  </>
                )}

                {currentGrafico2 === 'Barra' && (
                  <>
                    <ResponsiveContainer>
                      <BarChart width={300} height={300} data={graficoPeriodo2}>
                        <CartesianGrid />
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip formatter={value => CurrencyFormat(value)} />
                        <Legend />
                      //<Brush
                          dataKey="key"
                          height={50}
                          travellerWidth={10}
                          startIndex={controlBrush.startIndex}
                          endIndex={controlBrush.endIndex}
                          onChange={value => setControlBrush(value)}
                          width={300}
                          stroke="#5c89a8"
                        ></Brush> //
                        <Bar dataKey="valor" fill="rgb(99, 223, 109)" >
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </>
                )}

                {currentGrafico2 === 'Tabela' && (
                  <Grid container item lg={12} sm={12} xs={12}>
                    <Grid container item lg={1} sm={1} xs={1} ></Grid>

                    <Grid container item lg={9} sm={9} xs={9}>
                     // COMPARAÇÃO DOS VALORES DOS ULTIMOS 3 ANOS //
                        <TableNoPagination
                          loading={loading}
                          columns={[columnsLabel2]}
                          data={graficoPeriodo2}
                          keys={['key', 'valor']}

                        />
                    </Grid>
                    <Grid container item lg={1} sm={1} xs={1}></Grid>
                  </Grid>

                )}
              </Grid>
            )}
           // Gráficos - DOAÇÕES MENSAL COMPARATIVO ULTIMOS 3 ANOS
            {filterDateSelected === 2 &&  (
              <Grid container style={{ height: 350 }}>
                {currentGrafico2 === 'Linha' && (
                  <>
                    <ResponsiveContainer>
                      <LineChart
                        width={300}
                        height={300}
                        data={dados[filterDateSelected]}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <CartesianGrid />
                        <Tooltip formatter={value => CurrencyFormat(value)} />
                        <Legend />

                        {comparaAnos.includes(
                          pegaUltimosAnos().anoRetrasado,
                        ) && (
                          <Line
                            type="monotone"
                            dataKey={pegaUltimosAnos().anoRetrasado}
                            strokeWidth={2}
                            activeDot={{ r: 8 }}
                            stroke="#BE6265"
                          />
                        )}

                        {comparaAnos.includes(pegaUltimosAnos().anoPassado) && (
                          <Line
                            type="monotone"
                            dataKey={pegaUltimosAnos().anoPassado}
                            strokeWidth={2}
                            activeDot={{ r: 8 }}
                            stroke="#157ABE"
                          />
                        )}

                        {comparaAnos.includes(pegaUltimosAnos().anoAtual) && (
                          <Line
                            type="monotone"
                            dataKey={pegaUltimosAnos().anoAtual}
                            strokeWidth={2}
                            activeDot={{ r: 8 }}
                            stroke="rgb(99, 223, 109)"
                          />
                        )}
                      </LineChart>
                    </ResponsiveContainer>
                  </>
                )}

                {currentGrafico2 === 'Barra' && (
                  <>
                    <ResponsiveContainer>
                      <BarChart
                        width={300}
                        height={300}
                        data={dados[filterDateSelected]}
                      >
                        <CartesianGrid />
                        <XAxis dataKey="mes" />
                        <YAxis />
                        <Tooltip formatter={value => CurrencyFormat(value)} />
                        <Legend />

                        {comparaAnos.includes(
                          pegaUltimosAnos().anoRetrasado,
                        ) && (
                          <Bar
                            dataKey={pegaUltimosAnos().anoRetrasado}
                            fill="#c45c6f"
                            >
                          </Bar>
                        )}

                        {comparaAnos.includes(pegaUltimosAnos().anoPassado) && (
                          <Bar
                            dataKey={pegaUltimosAnos().anoPassado}
                            fill="#157ABE"
                            >
                          </Bar>
                        )}

                        {comparaAnos.includes(pegaUltimosAnos().anoAtual) && (
                          <Bar
                            dataKey={pegaUltimosAnos().anoAtual}
                            fill="rgb(99, 223, 109)"
                          >
                            </Bar>
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                  </>
                )}

                {currentGrafico2 === 'Tabela' && (
                  <Grid container item lg={12} sm={12} xs={12}>
                    <Grid container item lg={1} sm={1} xs={1} ></Grid>

                    <Grid container item lg={9} sm={9} xs={9}>
                      // COMPARAÇÃO DOS VALORES DOS ULTIMOS 3 ANOS //
                      <InvisibleContent visible={comparaAnos.includes(
                        pegaUltimosAnos().anoRetrasado) && comparaAnos.includes(
                        pegaUltimosAnos().anoPassado) && comparaAnos.includes(
                        pegaUltimosAnos().anoAtual)}
                      >
                        <TableNoPagination
                          loading={loading}
                          columns={[columnsLabel]}
                          data={data}
                          keys={['mes', 'ano_atual', 'ano_passado', 'ano_retrasado']}

                        />
                      </InvisibleContent>

                     // COMPARAÇÃO DOS VALORES DO ANO RETRASADO COM O ANO PASSADO//
                      <InvisibleContent visible={comparaAnos.includes(
                        pegaUltimosAnos().anoRetrasado) && comparaAnos.includes(
                        pegaUltimosAnos().anoPassado)  && !comparaAnos.includes(
                          pegaUltimosAnos().anoAtual)}
                      >
                          <TableNoPagination
                            loading={loading}
                            columns={[columnsLabel]}
                            data={data}
                            keys={['mes', 'ano_passado', 'ano_retrasado']}
                          />
                      </InvisibleContent>

                  // COMPARAÇÃO DOS VALORES DO ANO RETRADO COM O ANO ATUAL //
                      <InvisibleContent visible={comparaAnos.includes(
                        pegaUltimosAnos().anoRetrasado) && comparaAnos.includes(
                        pegaUltimosAnos().anoAtual)  && !comparaAnos.includes(
                          pegaUltimosAnos().anoPassado)}
                      >
                          <TableNoPagination
                            loading={loading}
                            columns={[columnsLabel]}
                            data={data}
                            keys={['mes', 'ano_atual', 'ano_retrasado']}
                          />
                      </InvisibleContent>

                  // COMPARAÇÃO DOS VALORES DO ANO PASSADO COM O ANO ATUAL//
                      <InvisibleContent visible={comparaAnos.includes(
                        pegaUltimosAnos().anoAtual) && comparaAnos.includes(
                        pegaUltimosAnos().anoPassado) && !comparaAnos.includes(
                          pegaUltimosAnos().anoRetrasado)}
                      >
                        <TableNoPagination
                          loading={loading}
                          columns={[columnsLabel]}
                          data={data}
                          keys={['mes', 'ano_atual', 'ano_passado']}
                        />
                      </InvisibleContent>

                  //* SOMENTE VALOR DO ANO ATUAL //
                      <InvisibleContent visible={comparaAnos.includes(
                        pegaUltimosAnos().anoAtual) && !comparaAnos.includes(
                          pegaUltimosAnos().anoPassado)  && !comparaAnos.includes(
                            pegaUltimosAnos().anoRetrasado)}
                      >
                        <TableNoPagination
                          loading={loading}
                          columns={[columnsLabel]}
                          data={data}
                          keys={['mes', 'ano_atual']}
                        />
                      </InvisibleContent>

                    //SOMENTE VALOR DO ANO PASSADO //
                      <InvisibleContent visible={comparaAnos.includes(
                        pegaUltimosAnos().anoPassado) && !comparaAnos.includes(
                          pegaUltimosAnos().anoAtual) && !comparaAnos.includes(
                            pegaUltimosAnos().anoRetrasado)}
                      >
                        <TableNoPagination
                          loading={loading}
                          columns={[columnsLabel]}
                          data={data}
                          keys={['mes', 'ano_passado']}
                        />
                      </InvisibleContent>

                    // SOMENTE VALOR DO ANO RETRASADO //
                      <InvisibleContent visible={ comparaAnos.includes(
                        pegaUltimosAnos().anoRetrasado) && !comparaAnos.includes(
                          pegaUltimosAnos().anoPassado)  && !comparaAnos.includes(
                            pegaUltimosAnos().anoAtual)}
                      >
                        <TableNoPagination
                          loading={loading}
                          columns={[columnsLabel]}
                          data={data}
                          keys={['mes', 'ano_retrasado']}
                        />
                      </InvisibleContent>
                    </Grid>
                    <Grid container item lg={1} sm={1} xs={1}></Grid>
                  </Grid>
                )}
              </Grid>
            )}

            ///Gráficos - DOAÇÕES ANUAIS COMPARATIVO ULTIMOS 3 ANOS//
            {filterDateSelected === 3 &&  (
              <Grid container style={{ height: 350, paddingTop: 10 }}>

                {currentGrafico === 'Barra' && (
                  <>
                    <ResponsiveContainer>
                      <BarChart
                        width={300}
                        height={300}
                        data={dados[filterDateSelected]}
                      >
                        <CartesianGrid />
                        <XAxis dataKey="mes" />
                        <YAxis />
                        <Tooltip formatter={value => CurrencyFormat(value)} />
                        <Legend />

                        {comparaAnos.includes(
                          pegaUltimosAnos().anoRetrasado,
                        ) && (
                          <Bar
                            dataKey={pegaUltimosAnos().anoRetrasado}
                            fill="#c45c6f"
                            >
                            <LabelList dataKey={pegaUltimosAnos().anoRetrasado} position="insideTop" />
                          </Bar>
                        )}

                        {comparaAnos.includes(pegaUltimosAnos().anoPassado) && (
                          <Bar
                            dataKey={pegaUltimosAnos().anoPassado}
                            fill="#157ABE"
                            >
                            <LabelList dataKey={pegaUltimosAnos().anoPassado} position="insideTop" />
                          </Bar>
                        )}

                        {comparaAnos.includes(pegaUltimosAnos().anoAtual) && (
                          <Bar
                            dataKey={pegaUltimosAnos().anoAtual}
                            fill="rgb(99, 223, 109)"
                          >
                              <LabelList dataKey={pegaUltimosAnos().anoAtual} position="insideTop" />
                            </Bar>
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                  </>
                )}
              </Grid>
            )}

          // Gráficos - DOAÇÕES POR PERÍODO COMPARATIVO ULTIMOS 3 ANOS//
            {filterDateSelected === 4 &&  (
              <Grid container style={{ height: 350, paddingTop: 10 }}>

                {currentGrafico === 'Barra' && (
                  <>
                    <ResponsiveContainer>
                      <BarChart
                        width={300}
                        height={300}
                        data={dados[filterDateSelected]}
                      >
                        <CartesianGrid />
                        <XAxis dataKey="mes" />
                        <YAxis />
                        <Tooltip formatter={value => CurrencyFormat(value)} />
                        <Legend />

                        {comparaAnos.includes(
                          pegaUltimosAnos().anoRetrasado,
                        ) && (
                          <Bar
                            dataKey={pegaUltimosAnos().anoRetrasado}
                            fill="#c45c6f"
                            >
                            <LabelList dataKey={pegaUltimosAnos().anoRetrasado} position="insideTop" />
                          </Bar>
                        )}

                        {comparaAnos.includes(pegaUltimosAnos().anoPassado) && (
                          <Bar
                            dataKey={pegaUltimosAnos().anoPassado}
                            fill="#157ABE"
                            >
                            <LabelList dataKey={pegaUltimosAnos().anoPassado} position="insideTop" />
                          </Bar>
                        )}

                        {comparaAnos.includes(pegaUltimosAnos().anoAtual) && (
                          <Bar
                            dataKey={pegaUltimosAnos().anoAtual}
                            fill="rgb(99, 223, 109)"
                          >
                              <LabelList dataKey={pegaUltimosAnos().anoAtual} position="insideTop" />
                            </Bar>
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                  </>
                )}
              </Grid>
            )}

             {filterDateSelected === 5 && (
              <Grid container style={{ height: 400 }}>
                {currentGrafico === 'Linha' && (
                  <>
                    <ResponsiveContainer>
                      <LineChart width={300} height={300} data={graficoPeriodo}>
                        <XAxis dataKey="key" />
                        <YAxis />
                        <CartesianGrid />
                        <Tooltip formatter={value => CurrencyFormat(value)} />
                        <Legend />

                        <Line
                          type="monotone"
                          dataKey="valor"
                          strokeWidth={2}
                          activeDot={{ r: 8 }}
                          stroke="rgb(99, 223, 109)"
                        >
                           <LabelList dataKey='valor' position="top" />
                         </Line>
                       // <Brush
                          dataKey="key"
                          height={50}
                          travellerWidth={10}
                          startIndex={controlBrush.startIndex}
                          endIndex={controlBrush.endIndex}
                          onChange={value => setControlBrush(value)}
                          padding={{ right: 100 }}
                          width={300}
                          stroke="#5c89a8"
                        ></Brush> //
                      </LineChart>
                    </ResponsiveContainer>
                  </>
                )}

                {currentGrafico === 'Barra' && (
                  <>
                    <ResponsiveContainer>
                      <BarChart width={300} height={300} data={graficoPeriodo}>
                        <CartesianGrid />
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip formatter={value => CurrencyFormat(value)} />
                        <Legend />
                       // <Brush
                          dataKey="key"
                          height={50}
                          travellerWidth={10}
                          startIndex={controlBrush.startIndex}
                          endIndex={controlBrush.endIndex}
                          onChange={value => setControlBrush(value)}
                          width={300}
                          stroke="#5c89a8"
                        ></Brush> //
                        <Bar dataKey="valor" fill="rgb(99, 223, 109)" >
                          <LabelList dataKey='valor' position="top" />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    */}
      {/*  Filtros */}
    </>
  );
};

export default Graficos;
