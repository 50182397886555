import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';

import User from '../HeaderUser';
import ItemMenu from './itemMenu';
import SelectEntidade from '@components/Amex/SelectEntidade';

import {
  routesAdminMenu,
  IRouteMenu,
} from '@router/routes/Cliente.admin.config.routes';
import { routesOperacionalMenu } from '@router/routes/Cliente.operacional.config.routes';
import { routesAdminMenu as routesAMEXAdminMenu } from '@router/routes/Amex.admin.config.routes';

import { useAuth } from '@hooks/AuthenticateContext';

export default function MenuHeader() {
  const { user } = useAuth();
  const [routes, setRoutes] = useState<IRouteMenu[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (user.role === 'ADM CLIENTE - GESTOR') {
      setRoutes(routesAdminMenu);
    } else if (user.role === 'OPERACIONAL - BASE' && user.entidade_id === 7)  {
      setRoutes(routesOperacionalMenu);
    } else if (user.role === 'ADM SOCIALLIS') {
      setRoutes(routesAMEXAdminMenu);
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        {routes
          .filter(
            route => route.menu === 'header' && route.roles.includes(user.role),
          )
          .map(routeMenuHeader => (
            <ItemMenu
              key={routeMenuHeader.index}
              path={routeMenuHeader.path ? routeMenuHeader.path : ''}
              Icon={routeMenuHeader.Icon as React.ReactElement}
              label={routeMenuHeader.label ? routeMenuHeader.label : ''}
            />
          ))}
        <div
          style={{
            flex: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            height: 64,
          }}
        >
          {user.role === 'ADM SOCIALLIS' && <SelectEntidade />}
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            height: 64,
          }}
        >
          <User />
        </div>
      </div>
    </div>
  );
}
