import React, { useCallback, useState, useEffect } from "react";


import BarChartBoxCadastros from "@src/components/Shared/BarChartBoxCadastros";
import InvisibleContent from '@components/Shared/InvisibleContent';


import PieChart from "@src/components/Shared/PieChart";


//FILTRO
import { Grid } from '@material-ui/core';
import { SubTitle, TitlePage } from '@styles/styles.global';
import DashboardServices, { IItemsDashboardMes, IItemsMes } from './services';
import { useSnackbar } from 'notistack';
import { useAuth } from '@hooks/AuthenticateContext';

import {
  Container,
  Content,
} from "./styles";

const Cadastros: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [comparacaoMes, setComparacaoMes] = useState<IItemsDashboardMes[]>([]);

  const setValuesOrErrorDashboard = (error: boolean, response: any) => {
    if (!error && response) {
      setComparacaoMes(response.comparacao_mes);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };


  const loadTipoDashboard = async () => {
    setLoading(true);
    const dashboardServices = new DashboardServices();
    ///await setStatus_ativo(tipo);
      const { error, response } = await dashboardServices.loadDashboardCadastrosNovos();
      setValuesOrErrorDashboard(error, response);
  };

  //Bloqueio inspecionar
  useEffect(() => {
    if (user.role !== 'ADM SOCIALLIS') {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'F12') {
          event.preventDefault();
          alert('F12 está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'I') {
          event.preventDefault();
          alert('Ctrl+Shift+I está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'J') {
          event.preventDefault();
          alert('Ctrl+Shift+J está desativado.');
        }
        if (event.ctrlKey && event.key === 'U') {
          event.preventDefault();
          alert('Ctrl+U está desativado.');
        }
      };

      const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        alert('O botão direito do mouse está desativado.');
      };

      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('contextmenu', handleContextMenu);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, [user.role]);

  //Iniciando os dados no grafico
  useEffect(() => {
    loadTipoDashboard();
    window.document.title = 'CRM - Dashboard';
  }, []);

  // const handleOpen = () => {
  //   setFiltros(!filtros);
  // };

  return (

    <>



        <Grid
          container
          item
          md={12}
          lg={12}
          xs={12}
          style={{paddingTop: 80,  alignItems: 'center',
          justifyContent: 'center'}}
        >
          <TitlePage>{user.nome},</TitlePage>
        </Grid>

        <Grid
          container
          item
          md={12}
          lg={12}
          xs={12}
          style={{paddingTop: 10, alignItems: 'center',
          justifyContent: 'center',}}
        >
          <p style={{ fontSize: 16, fontWeight: 'bold' }}> Seja bem-vindo(a) ao CRM - Socisllis Tecnologia</p>

        </Grid>



    </>
  );
}

export default Cadastros;
