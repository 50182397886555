import React from 'react';

import Cadastros from '@pages/Private/shared/ListPessoas';
import EditCadastro from '@pages/Private/shared/EditCadastro';
import NovoCadastro from '@src/Pages/Private/Cliente/shared/NovoCadastro';

import EditarUsuario from '@pages/Private/shared/EditarUsuario';
import ListaLigacao from '@pages/Private/Cliente/Operacional/ListaLigacao';
import WebPhoneAtivo from '@src/Pages/Private/Cliente/Operacional/WebPhoneAtivo';
import DoacoesMeta from '@pages/Private/Cliente/Operacional/DoacoesMeta';
import InserirDoacoes from '@src/Pages/Private/Cliente/Admin/InsercaoDoacoes';



import { ContactPage } from '@assets/Icons';
import { PhoneInTalk, FavoriteBorderOutlined, BarChart, LoyaltyOutlined  } from '@material-ui/icons';
import ListCorrespondenciaDevolvida from '@src/Pages/Private/Cliente/Operacional/CorrespondenciaDevolvida';
import { ForwardToInbox, mdiDatabaseSearch, Article } from '@assets/Icons/';
import DashboardCadastros from '@pages/Private/Cliente/Operacional/Dashboard'


import WebPhoneReceptivo from '@src/Pages/Private/Cliente/Operacional/WebPhoneReceptivo';

export interface IRoute {
  index: number | null;
  path: string;
  component: React.ComponentType<any>;
  roles: string[];
}

export interface IRouteMenu {
  index: number;
  path?: string;
  Icon?: React.ReactNode;
  label: string;
  roles: string[];
  menu: 'lateral' | 'header';
  subMenu?: IRouteMenu[];
}

export const routesOperacional: IRoute[] = [
  {
    index: 1,
    path: '/home',
    component: DoacoesMeta,
    roles: ['OPERACIONAL - FINANCEIRO'],
  },
  {
    index: 1,
    path: '/home',
    component: DashboardCadastros,
    roles: ['OPERACIONAL - FINANCEIRO'],
  },

  {
    index: 2,
    path: '/cadastros/',
    component: Cadastros,
    roles: ['OPERACIONAL - FINANCEIRO'],
  },
  {
    index: 3,
    path: '/cadastros/novo',
    component: NovoCadastro,
    roles: ['OPERACIONAL - FINANCEIRO'],
  },
  {
    path: '/cadastro/:idCadastro',
    component: EditCadastro,
    roles: ['OPERACIONAL - FINANCEIRO'],
    index: null,
  },
  {
    path: '/ligacoes',
    component: ListaLigacao,
    roles: ['OPERACIONAL - FINANCEIRO'],
    index: 6,
  },
  {
    index: null,
    path: '/usuario/editar',
    component: EditarUsuario,
    roles: ['OPERACIONAL - FINANCEIRO'],
  },
  {
    index: 4,
    path: '/inserir/doacoes',
    component: InserirDoacoes,
    roles: ['OPERACIONAL - FINANCEIRO'],
  },
  // {
  //   index: null,
  //   path: '/webphone/ativo',
  //   component: WebPhoneAtivo,
  //   roles: ['OPERACIONAL - FINANCEIRO'],
  // },
];

export const routesOperacionalFinanceiroMenu: IRouteMenu[] = [
  {
    index: 1,
    path: '/home',
    label: 'Dulcete',
    Icon: <FavoriteBorderOutlined />,
    roles: ['OPERACIONAL - FINANCEIRO'],
    menu: 'header',
  },
  {
    index: 2,
    label: 'Cadastros',
    path: '/cadastros',
    Icon: ContactPage,
    roles: ['OPERACIONAL - FINANCEIRO'],
    menu: 'lateral',
    subMenu: [
      {
        index: 2.2,
        path: '/cadastros',
        label: 'Gerenciar',
        roles: ['OPERACIONAL - FINANCEIRO'],
        menu: 'lateral',
      },
      {
        index: 2.2,
        path: '/cadastros/novo',
        label: 'Novo',
        roles: ['OPERACIONAL - FINANCEIRO'],
        menu: 'lateral',
      },
    ],
  },
  {
    index: 3,
    label: 'Inserção de doações',
    path: '/inserir/doacoes',
    Icon: LoyaltyOutlined,
    roles: ['OPERACIONAL - FINANCEIRO'],
    menu: 'lateral',
  },
  {
    index: 5,
    label: 'Ligações',
    path: '/ligacoes',
    Icon: PhoneInTalk,
    roles: ['OPERACIONAL - FINANCEIRO'],
    menu: 'lateral',
  },
  {
    index: 6,
    label: 'Dashboard',
    path: '/home',
    Icon: <BarChart />,
    roles: ['OPERACIONAL - FINANCEIRO'],
    menu: 'header',
  },


];
