import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdminLayout from '@layouts/Amex';
import { FooterTelefoniaProvider } from '@hooks/FooterTelefonia';


import { FooterTelefoniaProvider as FooterTelefoniaSemWebPhoneProvider } from '@hooks/FooterTelefoniaSemWebPhone';
// import { getRouteByID } from '@hooks/getFuncionalidades';

import {
  routesOperacional,
  IRoute,
} from '../routes/Cliente.operacional.base.config.routes';

import { useAuth } from '@hooks/AuthenticateContext';
import WebPhoneAtivo from '@src/Pages/Private/Cliente/Operacional/WebPhoneAtivo';
import WebPhoneReceptivo from '@src/Pages/Private/Cliente/Operacional/WebPhoneReceptivo';

function RenderRoutes(route: IRoute, key: number, user: any) {
  const ComponentRoute = route.component;
  // if (getRouteByID(route.id)) {

  if (route.roles.includes(user.role)) {
    return (
      <Route
        key={key}
        path={route.path}
        exact
        component={() =>
          user.telefonia === 'Zenvia' ? (
            <AdminLayout>
              <FooterTelefoniaProvider>
                <ComponentRoute />
              </FooterTelefoniaProvider>
            </AdminLayout>
          ) :  (
            <AdminLayout>
              <FooterTelefoniaSemWebPhoneProvider>
                <ComponentRoute />
              </FooterTelefoniaSemWebPhoneProvider>
            </AdminLayout>
          )
        }
      />
    );
  }
  // }
}

function RouteClienteAdmin() {
  const { user } = useAuth();
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/"
          component={() => <Redirect to={routesOperacional[0].path} />}
        />

        {routesOperacional.map((route, i) => RenderRoutes(route, i, user))}

        <Route
          path="/webphone/ativo"
          exact
          component={
            user.telefonia === 'Zenvia'
              ? WebPhoneAtivo
              : () => <Redirect to={routesOperacional[0].path} />
          }
        />
        <Route
          path="/webphone/receptivo"
          exact
          component={
            user.telefonia === 'Zenvia'
              ? WebPhoneReceptivo
              : () => <Redirect to={routesOperacional[0].path} />
          }
        />
        <Route
          path="*"
          component={() => {
            return <Redirect to={routesOperacional[0].path} />;
          }}
        />
      </Switch>
    </div>
  );
}

export default RouteClienteAdmin;
