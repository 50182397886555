import React, { useCallback, useState, useEffect } from "react";

import WalletBox from "@components/Shared/WalletBox";
import PieChartBox from "@components/Shared/PieChartBox";
import HistoryBoxDoacoes from "@src/components/Shared/HistoryBoxDoacoes";
import HistoryBoxConversaoDoadores from "@src/components/Shared/HistoryBoxConversaoDoadores";
import HistoryBoxMedia from "@src/components/Shared/HistoryBoxMedia";
import BarChartBoxCadastros from "@src/components/Shared/BarChartBoxCadastros";
import BarChartBoxDoadores from "@src/components/Shared/BarChartBoxDoadores";
import BarChartBoxOrigens from "@components/Shared/BarChartBoxOrigens";
import BarChartBoxContribuicao from "@components/Shared/BarChartBoxContribuicao";
import BarChartBoxCorrespondencia from "@components/Shared/BarChartBoxCorrespondencia";

import PieChart from "@src/components/Shared/PieChart";
import { useAuth } from '@hooks/AuthenticateContext';


//FILTRO
import Filtros from '@src/components/Shared/Filtros';
import { Grid } from '@material-ui/core';
import DatePicker from '@components/Shared/DatePicker';
import Button from '@components/Shared/Buttons/Button';
import { SubTitle } from '@styles/styles.global';
import { getColors } from '@styles/colorsTheme';
import { TuneOutlined } from '@material-ui/icons';
//FIM - FILTRO

import DashboardServices, { IItemsDashboardMes, IItemsMes } from './services';
import { useSnackbar } from 'notistack';

import {
  Container,
  Content,
} from "./styles";
import { StringLocale } from "yup";


const Cadastros: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  //botões tipo dashboard
  const [status_ativo, setStatus_ativo] = useState('Novos');
  //
  const { user, signOut } = useAuth();
  const [totalCadastros, setTotalCadastros] = useState<number>(0);
  const [totalDoacoes, setTotalDoacoes] = useState<number>(0);
  const [valorTotalDoacoes, setValorTotalDoacoes] = useState<number>(0);
  const [mediaPerCaptaAnual, setMediaPerCaptaAnual] = useState<number>(0);
  const [comparacaoMes, setComparacaoMes] = useState<IItemsDashboardMes[]>([]);
  const [origem_cadastro, setOrigemCadastro] = useState<IItemsMes[]>([]);
  const [forma_contribuicao, setFormaContribuicao] = useState<IItemsMes[]>([]);
  const [tipo_correspondencia, setTipoCorrespondencia] = useState<IItemsMes[]>([]);

  // //FILTRO
  // const [filtros, setFiltros] = useState(false);

  // const [filterDateSelected, setFilterDateSelected] = useState(0);

  // const checkColorSelected = useCallback(
  //   (indexGrafico: number) => {
  //     return filterDateSelected === indexGrafico ? 'primary' : 'default';
  //   },
  //   [filterDateSelected],
  // );

  // const pegaUltimosAnos = () => {
  //   const anoAtual = new Date().getFullYear();

  //   const anoPassado = anoAtual - 1;
  //   const anoRetrasado = anoAtual - 2;

  //   return {
  //     anoAtual,
  //     anoPassado,
  //     anoRetrasado,
  //   };
  // };

  // const [comparaAnos, setComparaAnos] = useState<number[]>([
  //   pegaUltimosAnos().anoAtual,
  // ]);

  // const controlaFiltroDeAnos = (anoSelecionado: number) => {
  //   const existe = comparaAnos?.includes(anoSelecionado);

  //   if (existe) {
  //     setComparaAnos(comparaAnos?.filter(ano => ano !== anoSelecionado));
  //   } else {
  //     setComparaAnos([...comparaAnos, anoSelecionado]);
  //   }
  // };



  // const changeFilterGrafiph = useCallback((index: number) => {
  //   if (index === 3) {
  //     // setCurrentGrafico('Barra');
  //   }
  //   setFilterDateSelected(index);
  // }, []);

  //FIM - FILTRO



  const setValuesOrErrorDashboard = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalCadastros(response.total_cadastros);
      setTotalDoacoes(response.total_doacoes);
      setValorTotalDoacoes(response.valor_total_doacoes);
      setMediaPerCaptaAnual(response.media_per_capta_anual);
      setComparacaoMes(response.comparacao_mes);
      setOrigemCadastro(response.origem_cadastro);
      setFormaContribuicao(response.forma_contribuicao);
      setTipoCorrespondencia(response.tipo_correspondencia);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };


  const loadTipoDashboard = async (tipo: string) => {
    setLoading(true);
    const dashboardServices = new DashboardServices();
    await setStatus_ativo(tipo);

    if (tipo == 'Geral') {
      const { error, response } = await dashboardServices.loadDashboardGeral();
      setValuesOrErrorDashboard(error, response);
    } else {
      const { error, response } = await dashboardServices.loadDashboardCadastrosNovos();
      setValuesOrErrorDashboard(error, response);
    }
  };

  //Iniciando os dados no grafico
  useEffect(() => {
    loadTipoDashboard('Novos');
    window.document.title = 'CRM - Dashboard';
  }, []);

  //Bloqueio inspecionar
  useEffect(() => {
    if (user.role !== 'ADM SOCIALLIS') {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'F12') {
          event.preventDefault();
          alert('F12 está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'I') {
          event.preventDefault();
          alert('Ctrl+Shift+I está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'J') {
          event.preventDefault();
          alert('Ctrl+Shift+J está desativado.');
        }
        if (event.ctrlKey && event.key === 'U') {
          event.preventDefault();
          alert('Ctrl+U está desativado.');
        }
      };

      const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        alert('O botão direito do mouse está desativado.');
      };

      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('contextmenu', handleContextMenu);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, [user.role]);
  // const handleOpen = () => {
  //   setFiltros(!filtros);
  // };

  return (
    <Container>
      <Grid container>
        <Grid
          item
          sm={2}
          xs={6}
          style={{
            alignSelf: 'center',
            padding: 5,
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <Button
            color={status_ativo === 'Novos' ? 'secondary' : 'default'}
            style={{ width: '100%' }}
            onClick={() => loadTipoDashboard('Novos')}
          >
            Novos
          </Button>
        </Grid>
        <Grid
          item
          sm={2}
          xs={6}
          style={{
            alignSelf: 'center',
            padding: 5,
          }}
        >
          <Button
            color={status_ativo === 'Geral' ? 'secondary' : 'default'}
            style={{ width: '100%' }}
            onClick={() => loadTipoDashboard('Geral')}
          >
            Geral
          </Button>
        </Grid>
      </Grid>

      {/* COMPONENTES DO GRÁFICO */}

      <Content>
        <WalletBox
          title="Cadastros (Anual)"
          color="#8c83de"
          amount={totalCadastros}
          icon="cadastro"
        />

        <WalletBox
          title="Doações (Anual)"
          color="#b25a8a"
          amount={totalDoacoes}
          icon="doacao"
        />

        <WalletBox
          title="Valor das Doações (Anual)"
          color="#e0a8b4"
          amount={valorTotalDoacoes}
          icon="totalDoacao"
        />

        <WalletBox
          title="Média Per Capta (Anual)"
          color="#b0acc8"
          amount={mediaPerCaptaAnual}
          icon="mediaPerCapta"
        />

        <HistoryBoxDoacoes data={comparacaoMes} />

        <HistoryBoxMedia data={comparacaoMes} />

        {/* <PieChartBox data={comparacaoMes} /> */}

        <HistoryBoxConversaoDoadores data={comparacaoMes} />

        <PieChart
          title="Conversão para Doadores (Representação dentro do ano) "
          data={comparacaoMes}
        />

        <BarChartBoxCadastros
          title="Cadastros x Doadores"
          data={comparacaoMes}
        />

        <BarChartBoxDoadores
          title="Doadores x Quantidade Doação"
          data={comparacaoMes}
        />

        <BarChartBoxContribuicao
          title="Quantidade de Doações por Formas de Contribuicao"
          data={forma_contribuicao}
        />

        <BarChartBoxCorrespondencia
          title="Tipos de Correspondência"
          data={tipo_correspondencia}
        />

        <BarChartBoxOrigens
          title="Origens de Cadastro"
          data={origem_cadastro}
        />

      </Content>
    </Container>
  );
}

export default Cadastros;
